import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { mapboxSearch } from "../../core/service/api";

import styles from "../input-place/input-place.scss";

export const getLocationByString = async (locationString) => {
  if (locationString < 1) return;

  return new Promise((res) => {
    mapboxSearch(locationString)
      .then((results) => {
        const newOptions = results.data.features.map((result) => ({
          data: result,
          label: result.place_name,
        }));

        res(newOptions);
      })
      .catch(() => false);
  });
};

const useKeyPress = function (targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });

  return keyPressed;
};

const SearchSuggestions = ({ cursor, suggestions, onClick, setHovered }) => {
  const handleClick = (event, value) => {
    event.preventDefault();
    if (onClick) {
      onClick(value);
    }
  };

  return (
    <div className={styles.inputDropdown}>
      <ul>
        {suggestions.map((suggestion, index) => (
          <li key={`suggestion-${index}`}>
            <button
              tabIndex="0"
              role="button"
              className={classnames("place-btn", {
                ["active"]: index === cursor,
              })}
              onClick={(event) => handleClick(event, suggestion.label)}
              onMouseEnter={() => setHovered(suggestion)}
              onMouseLeave={() => setHovered(undefined)}
            >
              {suggestion.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const SearchIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path d="M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z" />
  </svg>
);

const Search = ({ id, isLoading, onSubmit }) => {
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  const [cursor, setCursor] = useState(-1);
  const [hovered, setHovered] = useState(undefined);

  const [suggestions, setSuggestions] = useState(undefined);
  const [inputValue, setInputValue] = useState("");

  const reset = () => {
    setCursor(-1);
    setHovered(undefined);
    setSuggestions(undefined);
  };

  const handleBlur = (event) => {
    const { relatedTarget } = event;
    if (relatedTarget && !relatedTarget.classList.contains("place-btn")) {
      reset();
    }
  };

  /**
   *
   * @param {ChangeEvent} event
   *
   * @returns void
   */
  const handleChange = (event) => {
    const { value } = event.target;

    setInputValue(value);

    if (value < 1) {
      setSuggestions(undefined);
    } else {
      getLocationByString(inputValue).then((res) => {
        setSuggestions(res);
      });
    }
  };

  const handleClick = (value) => {
    setInputValue(value);

    if (onSubmit) {
      onSubmit(value);
    }

    reset();
  };

  /**
   *
   * @param {SubmitEvent} event
   *
   * @returns void
   */
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (onSubmit) {
      /**
       * If there is not value, we'll assume this was triggered from the
       * no-data-overlay (otherwise the key down events would record the value).
       */
      if (!inputValue) {
        const searchForm =
          window.innerWidth > 800
            ? document.getElementById("desktop-search")
            : document.getElementById("mobile-search");

        // Assume there is only one input within the form.
        const [searchInput] = searchForm.getElementsByTagName("input");

        onSubmit(searchInput.value);
        setInputValue("");
      }

      onSubmit(inputValue);
      setInputValue("");
    }

    reset();
  };

  useEffect(() => {
    if (suggestions && downPress) {
      setCursor((prevState) =>
        prevState < suggestions.length - 1 ? prevState + 1 : 0
      );
    }
  }, [downPress]);
  useEffect(() => {
    if (suggestions && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : -1));
    }
  }, [upPress]);
  useEffect(() => {
    if (suggestions && enterPress && cursor >= 0) {
      setInputValue(suggestions[cursor].label);
    }
  }, [cursor, enterPress]);
  useEffect(() => {
    if (suggestions && hovered) {
      setCursor(suggestions.indexOf(hovered));
    }
  }, [hovered]);

  return (
    <div
      className={classnames(styles.inputPlace)}
      data-attribute="location-search"
    >
      <form id={id} onSubmit={handleSubmit}>
        <input
          autoFocus
          aria-label="Search"
          list="search-suggestions"
          onChange={(val) => {
            handleChange(val);
          }}
          placeholder="Search Location (Ex. New York)"
          type="text"
          value={inputValue}
          onBlur={handleBlur}
        />
        <button
          aria-label="Search"
          className={styles.inputBtn}
          disabled={isLoading}
          type="submit"
          onClick={handleSubmit}
        >
          <SearchIcon />
        </button>
        {suggestions &&
          suggestions[0] &&
          suggestions[0].label !== inputValue &&
          suggestions.length > 0 && (
            <SearchSuggestions
              suggestions={suggestions}
              cursor={cursor}
              onClick={handleClick}
              setHovered={setHovered}
            />
          )}
      </form>
    </div>
  );
};

export default Search;
