import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Store from './core/store';

import './assets/scss/global.scss';
import Default from './templates/default/default';

const App = () => (
  <Store>
    <Router>
      <Switch>
        <Route path="/">
          <Default />
        </Route>
      </Switch>
    </Router>
  </Store>
);

render(<App />, document.getElementById('root'));
