const Reducer = (state, action) => {
  switch (action.type) {
    case "set_map": {
      return {
        ...state,
        map: action.payload,
      };
    }
    case "set_bound": {
      return {
        ...state,
        bound: action.payload,
      };
    }
    case "set_can-search-on-map-move": {
      console.log("setting movend! ", action.payload);
      return {
        ...state,
        canSearchOnMoveEnd: action.payload,
      };
    }
    case "set_loading": {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case "set_data": {
      return {
        ...state,
        data: action.payload,
      };
    }
    case "set_popup": {
      return {
        ...state,
        activePopup: action.payload,
      };
    }
    case "set_permit": {
      return {
        ...state,
        activePermit: action.payload,
      };
    }
    case "set_contractors": {
      return {
        ...state,
        contractors: action.payload,
      };
    }
    case "set_modal": {
      return {
        ...state,
        modalOpen: action.payload,
      };
    }
    case "set_side-widget": {
      return {
        ...state,
        widgetOpen: action.payload,
      };
    }
    case "set_menu-overlay": {
      return {
        ...state,
        menuOverlay: action.payload,
      };
    }
    case "set_filter-tab": {
      return {
        ...state,
        filterOpen: action.payload,
      };
    }
    case "set_permit-panel": {
      return {
        ...state,
        permitMobilePanel: action.payload,
      };
    }
    case "set_filter-types": {
      return {
        ...state,
        selectedTypes: action.payload.selectedTypes,
        preSelectedTypes: action.payload.preSelectedTypes,
      };
    }
    case "set_filter-types-dropdown": {
      return {
        ...state,
        showTypesDropdown: action.payload,
      };
    }
    case "set_filter-trades": {
      return {
        ...state,
        selectedTrades: action.payload.selectedTrades,
        preSelectedTrades: action.payload.preSelectedTrades,
      };
    }
    case "set_filter-trades-dropdown": {
      return {
        ...state,
        showTradesDropdown: action.payload,
      };
    }
    case "set_mobile-panel-data": {
      return {
        ...state,
        address: action.payload.address,
        permits: action.payload.permits,
      };
    }
    case "set_filter-dates": {
      return {
        ...state,
        dateRangeType: action.payload.dateRangeType,
        dateRange: action.payload.dateRange,
        dateType: action.payload.dateType,
      };
    }
    case "set_filter-dates-dropdown": {
      return {
        ...state,
        showDateDropdown: action.payload.showDateDropdown,
        showDateRange: action.payload.showDateRange,
      };
    }
    case "set_permit-view-all": {
      return {
        ...state,
        showAllPermits: action.payload,
      };
    }
    case "set_permit-details-view-all": {
      return {
        ...state,
        showPermitDetails: action.payload,
      };
    }
    case "set_loader-text": {
      return {
        ...state,
        loaderText: action.payload,
      };
    }
    default:
      return state;
  }
};

export default Reducer;
