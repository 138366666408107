import React, { useState, useRef } from "react";

const ContractorDetails = ({ styles, contractor }) => {
  const [active, setActive] = useState(false);
  const [height, setHeight] = useState("0px");

  const content = useRef(null);

  const handleAccordion = () => {
    setActive(!active);
    setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
  };

  return (
    <div className={`${styles.row} ${styles.rowFull} ${styles.tab} ${active ? styles.active : ''}`}>
      <button
        onClick={() => handleAccordion()}
        className={`${styles.col} ${styles.tabLabel}`}
      >
        <p>{contractor.COMPANYNAME || "-"}</p>
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path d="M12 21l-12-18h24z" />
        </svg>
      </button>
      <div
        className={styles.tabContent}
        ref={content}
        style={{ maxHeight: `${height}` }}
      >
        <div className={styles.row}>
          <div className={styles.col}>Contractor Name</div>
          <div className={styles.col}>
            <strong>{contractor.CONTRACTORNAME}</strong>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>Contractor ID</div>
          <div className={styles.col}>
            <strong>{contractor.PERMITCONTRACTORID}</strong>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>Licence Type</div>
          <div className={styles.col}>{contractor.LICENCETYPE || "-"}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>Trade</div>
          <div className={styles.col}>{contractor.TRADE || "-"}</div>
        </div>
        <div className={`${styles.row} ${styles.rowFull}`}>
          <div className={styles.col}>Address 1</div>
          <div className={styles.col}>
            <strong>{contractor.ADDR1 || "No Address 1 stated"}</strong>
          </div>
        </div>
        <div className={`${styles.row} ${styles.rowFull}`}>
          <div className={styles.col}>Address 2</div>
          <div className={styles.col}>
            <strong>{contractor.ADDR2 || "No Address 2 stated"}</strong>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>City</div>
          <div className={styles.col}>{contractor.CITY || "-"}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>State</div>
          <div className={styles.col}>{contractor.STATEABBR || "-"}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>ZIP Code</div>
          <div className={styles.col}>{contractor.ZIPCODE || "-"}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>Phone No.</div>
          <div className={styles.col}>{contractor.PHONE || "-"}</div>
        </div>
      </div>
    </div>
  );
};
export default ContractorDetails;
