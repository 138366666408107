import React, { useContext, useEffect } from "react";
import FocusLock from "react-focus-lock";
import { Context } from "../../core/store";
import MainLogo from "../side-widget/main-logo";

import styles from "./modal.scss";

const Modal = () => {
  const [state, dispatch] = useContext(Context);
  const { modalOpen } = state;

  // Closes modal.
  const closeModal = () => {
    dispatch({
      type: "set_modal",
      payload: false,
    });
  };

  // Closes modal on `esc` press.
  const onKeyDown = (e) => {
    if (e.keyCode === 27) {
      closeModal();
    }
  };

  // Closes modal when user clicks on background.
  const handleBackgroundClick = (e) => {
    const { target } = e;
    if (target.classList.contains("modal")) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  return modalOpen ? (
    <div className={`${styles.modal} modal`} onClick={handleBackgroundClick}>
      <FocusLock>
        <div className={styles.modalContainer}>
          <button className={styles.modalCloseBtn} onClick={closeModal}>
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.5 16.084l-1.403 1.416-4.09-4.096-4.102 4.096-1.405-1.405 4.093-4.092-4.093-4.098 1.405-1.405 4.088 4.089 4.091-4.089 1.416 1.403-4.092 4.087 4.092 4.094z" />
            </svg>
          </button>
          <div className={styles.modalContent}>
            <div className={styles.header}>
              <MainLogo />
            </div>
            <div className={styles.body}>
              <p>
                Burnham PermitMapper&trade; is our proprietary web app that
                allows you to seamlessly search for construction details quickly
                and accurately.
              </p>
              <div className={styles.form}>
                <h3>Get notified when we launch new features</h3>
                <div className={styles.field}>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter email address"
                  />
                  <button type="button">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FocusLock>
    </div>
  ) : null;
};

export default Modal;
