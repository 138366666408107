import React, { useEffect, useRef, useContext, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Context } from '../../core/store';

import 'react-datepicker/dist/react-datepicker.css';

import styles from './input-date.scss';

const dateRangeOptions = [
  {
    value: 'all',
    label: 'All Dates',
  },
  {
    value: 'past-3-months',
    label: 'Past 3 Months',
  },
  {
    value: 'past-6-months',
    label: 'Past 6 Months',
  },
  {
    value: 'past-9-months',
    label: 'Past 9 Months',
  },
  {
    value: 'entire-year',
    label: `Entire Year (${new Date().getFullYear()})`,
  },
  {
    value: 'last-year',
    label: `Last Year (${new Date().getFullYear() - 1})`,
  },
  {
    value: 'specific',
    label: 'Specific date range',
  },
];

let eventAttached = false;

const InputDate = ({
  showDateRange,
  showDateDropdown,
  dateRangeType,
  dateRangeVal,
  setDateRangeVal,
  dateRangeTypeVal,
  setDateRangeTypeVal
}) => {
  const [, dispatch] = useContext(Context);
  const dateDropdownRef = useRef(null);
  const dateToggleRef = useRef(null);
  const dateRangeRef = useRef(null);

  const handleBlur = (e) => {
    const state = {
      showDateDropdown: false,
      showDateRange,
    };

    if (dateDropdownRef.current
        && !dateDropdownRef.current.contains(e.target)
        && !dateToggleRef.current.contains(e.target)) {
      dispatch({
        type: 'set_filter-dates-dropdown',
        payload: state,
      });
    }
  };

  const handleDesktopEvents = () => {
    if (window.innerWidth > 800 && !eventAttached) {
      document.addEventListener('mousedown', handleBlur, false);
      eventAttached = true;

      return () => {
        document.removeEventListener('mousedown', handleBlur, false);
      };
    } else {
      document.removeEventListener('mousedown', handleBlur, false);
    }
  }

  useEffect(() => {
    handleDesktopEvents();
  }, []);

  window.addEventListener('resize', handleDesktopEvents);

  return (
    <div className={styles.inputDate}>
      <button
        type="button"
        className={styles.inputDateBtn}
        aria-haspopup="listbox"
        onClick={() => {
          const state = {
            showDateDropdown: !showDateDropdown,
            showDateRange,
          };

          dispatch({
            type: 'set_filter-dates-dropdown',
            payload: state,
          });
        }}
        ref={dateToggleRef}
      >
        <div>
          {
            dateRangeType ? dateRangeType.label : 'Issue Date'
          }
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path d="M12 21l-12-18h24z" />
          </svg>
        </div>
      </button>

      {
        showDateDropdown && (
          <div className={styles.inputDateDropdown} ref={dateDropdownRef}>
            <ul>
              {
                dateRangeOptions.map((option, index) => (
                  <li key={`date-range-option-${index}`} className={dateRangeTypeVal && dateRangeTypeVal.value === option.value ? styles.active : ''}>
                    <button
                      type="button"
                      className={option.value}
                      onClick={() => {
                        setDateRangeTypeVal(option);

                        if (option.value === 'specific' && !dateRangeVal) {
                          setDateRangeVal({
                            from: new Date(),
                            to: new Date(),
                          });
                        }

                        const dropdownState = {
                          showDateDropdown,
                          showDateRange: option.value === 'specific',
                        };

                        dispatch({
                          type: 'set_filter-dates-dropdown',
                          payload: dropdownState,
                        });
                      }}
                    >
                      { option.label }
                    </button>
                  </li>
                ))
              }
            </ul>
            {
              showDateRange && (
                <div className={styles.dateRange} ref={dateRangeRef}>
                  <div className={styles.dateRangeContainer}>
                    <label>From</label>
                    <DatePicker
                      popperPlacement="top-start"
                      selected={dateRangeVal ? dateRangeVal.from : new Date()}
                      selectsStart
                      startDate={dateRangeVal ? dateRangeVal.from : new Date()}
                      endDate={dateRangeVal ? dateRangeVal.to : new Date()}
                      onChange={(startDate) => {
                        setDateRangeVal({
                          ...dateRangeVal,
                          from: startDate,
                        });
                      }}
                    />
                  </div>
                  <div className={styles.dateRangeContainer}>
                    <label>To</label>
                    <DatePicker
                      popperPlacement="top-end"
                      selected={dateRangeVal ? dateRangeVal.to : new Date()}
                      selectsEnd
                      startDate={dateRangeVal ? dateRangeVal.from : new Date()}
                      endDate={dateRangeVal ? dateRangeVal.to : new Date()}
                      minDate={dateRangeVal ? dateRangeVal.from : new Date()}
                      onChange={(endDate) => {
                        setDateRangeVal({
                          ...dateRangeVal,
                          to: endDate,
                        });
                      }}
                    />
                  </div>
                </div>
              )
            }
            <div className={styles.trigger}>
              <button
                type="button"
                className={styles.dateFilterBtn}
                onClick={() => {
                  const state = {
                    dateRange: dateRangeVal,
                    dateRangeType: dateRangeTypeVal,
                    dateType: dateRangeTypeVal,
                  };

                  dispatch({
                    type: 'set_filter-dates',
                    payload: state,
                  });

                  const dropdownState = {
                    showDateDropdown: false,
                    showDateRange: false,
                  };

                  dispatch({
                    type: 'set_filter-dates-dropdown',
                    payload: dropdownState,
                  });
                }}
              >
                Apply
              </button>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default InputDate;
