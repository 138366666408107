import React from 'react';

const MainLogo = ({ className }) => (
  <svg width="284" height="43" viewBox="0 0 284 43" className={className} fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M64.7665 4.17697C88.1354 4.17697 110.538 6.2997 126.535 10.0067V32.5566C110.537 36.2627 88.1324 38.3841 64.7665 38.3841C41.4022 38.3841 18.9992 36.2627 3.00135 32.5566V10.0067C18.9975 6.2997 41.3993 4.17697 64.7665 4.17697ZM1.1732 7.17162L0.00504782 7.45334L0 8.72244V35.1122L1.18119 35.3939C17.4554 39.3162 40.6291 41.5646 64.7665 41.5646C88.9056 41.5646 112.083 39.3162 128.353 35.3939L129.522 35.1122L129.535 33.8413V7.45334L128.365 7.17162C112.092 3.248 88.9102 0.997772 64.7665 0.997772C40.6232 0.997772 17.4436 3.248 1.1732 7.17162Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.4555 26.8143V13.0098H8.00195V29.6591H11.4555V26.8143Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M18.2777 20.6114C19.6414 19.9116 20.4125 18.7691 20.4125 17.1363C20.4125 14.0351 18.3875 13.0103 15.3949 13.0103H12.9678V15.8529H14.6908C15.9229 15.8529 17.0674 16.181 17.0674 17.7666C17.0674 19.1663 16.1639 19.7475 14.9537 19.7475H12.9678V22.2429H15.2187C16.6931 22.2429 17.706 22.921 17.706 24.6224C17.706 26.3462 16.5168 26.8147 15.1531 26.8147H12.9678V29.6596H15.6154C18.4531 29.6596 21.1595 28.2135 21.1595 24.8324C21.1595 22.7569 20.1925 21.1949 18.2777 20.6114Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M29.5806 26.9217C27.4659 26.6168 27.1025 25.0018 27.1025 23.3623V13.0094H23.646V23.3623C23.646 27.61 25.8195 29.7799 29.5806 30.03V26.9217Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M33.5928 13.0098V23.3627C33.5928 25.5617 32.9644 26.7046 31.092 26.9315V30.0296C34.8392 29.7764 37.0485 27.5859 37.0485 23.3627V13.0098H33.5928Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M43.6939 23.1536V13.0098H40.2395V29.6587H43.6939V23.1536Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M53.1128 25.719C53.003 24.0385 52.5188 22.2915 50.8236 21.8239V21.7776C52.5617 21.0314 53.3109 19.5621 53.3109 17.6021C53.3109 15.0844 51.529 13.0103 48.7111 13.0103H45.2058V15.8529H47.479C49.0186 15.8529 49.8557 16.5528 49.8557 18.1624C49.8557 19.8429 49.0186 20.5415 47.479 20.5415H45.2058V23.1528H47.1492C48.8874 23.1528 49.5259 23.9226 49.7678 25.6718C49.944 26.9993 49.9003 28.6094 50.3167 29.6596H53.772C53.1565 28.7262 53.1784 26.767 53.1128 25.719Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M82.6807 13.0098V19.3981H77.8335V22.4761H82.6807V29.6591H86.1359V13.0098H82.6807Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M76.3205 22.4761V13.0098H72.8669V29.6591H76.3205V22.4761Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M91.8021 27.9019L92.4503 25.9513H92.4545L93.369 23.2227H93.353L95.3991 17.1144H95.4117L96.7843 13.0103H93.6837L87.7192 29.6596H91.2161L91.8021 27.9019Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M97.8222 14.6997L96.2174 19.4993L97.4247 23.2223H94.9723L94.0591 25.9508H98.3262L99.5154 29.6587H103.102L97.8222 14.6997Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M107.92 17.977V13.0098H104.685V29.6587H107.92V17.977Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M118.375 29.6591H121.609V13.0098H118.375V29.6591Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M116.861 13.0098H116.745L113.268 24.4584H113.226L109.548 13.0098H109.433V22.4297L111.815 29.6591H114.478L116.861 22.3557V13.0098Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M69.6095 26.7483V13.0103H66.3743V21.2248L69.6095 26.7483Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M64.3093 20.7233L59.7801 13.0103H56.4094L63.9248 25.8425L66.1538 29.6596H69.5338L64.3093 20.7233Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M56.3459 15.9412V29.6591H59.5824V21.466L56.3459 15.9412Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M144.958 22.4199C144.958 21.9603 144.793 21.5761 144.467 21.2658C144.14 20.9556 143.56 20.6948 142.727 20.4835C141.72 20.229 140.933 19.8536 140.369 19.3579C139.804 18.8623 139.522 18.2391 139.522 17.4889C139.522 16.7012 139.826 16.0437 140.434 15.5155C141.043 14.9877 141.831 14.7233 142.799 14.7233C143.841 14.7233 144.67 15.0264 145.288 15.6318C145.905 16.2367 146.202 16.9392 146.18 17.7389L146.167 17.7804H144.938C144.938 17.1889 144.754 16.7105 144.385 16.3455C144.017 15.9804 143.488 15.7981 142.799 15.7981C142.158 15.7981 141.666 15.9514 141.324 16.259C140.982 16.5661 140.81 16.9669 140.81 17.4617C140.81 17.9003 140.996 18.2667 141.366 18.56C141.737 18.8533 142.339 19.111 143.172 19.333C144.175 19.5964 144.938 19.9842 145.461 20.4973C145.984 21.01 146.246 21.6456 146.246 22.4034C146.246 23.2169 145.932 23.8704 145.304 24.3648C144.676 24.8596 143.856 25.1065 142.845 25.1065C141.894 25.1065 141.053 24.8328 140.323 24.2854C139.593 23.7376 139.238 23.0065 139.26 22.0914L139.273 22.0499H140.503C140.503 22.6972 140.727 23.1893 141.177 23.5263C141.626 23.8637 142.182 24.0322 142.845 24.0322C143.494 24.0322 144.009 23.8878 144.388 23.5981C144.768 23.3088 144.958 22.916 144.958 22.4199Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M147.986 24.9612H149.401V17.4617H147.986V24.9612ZM147.986 15.5413H149.401V14.1483H147.986V15.5413Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M152.407 17.4617L152.499 18.4459C152.73 18.0897 153.023 17.8138 153.379 17.6172C153.734 17.4211 154.147 17.323 154.618 17.323C155.089 17.323 155.494 17.4385 155.831 17.6694C156.169 17.9003 156.424 18.2471 156.594 18.7089C156.816 18.2841 157.11 17.9467 157.477 17.697C157.843 17.4474 158.27 17.323 158.758 17.323C159.478 17.323 160.047 17.5851 160.466 18.1094C160.884 18.634 161.093 19.423 161.093 20.4764V24.9612H159.805V20.4626C159.805 19.7235 159.685 19.2001 159.445 18.8926C159.205 18.5854 158.847 18.4316 158.373 18.4316C157.932 18.4316 157.575 18.5935 157.303 18.9171C157.031 19.2407 156.87 19.6495 156.822 20.1438V24.9612H155.527V20.4626C155.527 19.7605 155.404 19.2461 155.158 18.9202C154.911 18.5948 154.557 18.4316 154.095 18.4316C153.702 18.4316 153.379 18.5172 153.127 18.6884C152.874 18.8591 152.68 19.0994 152.545 19.4092V24.9612H151.256V17.4617H152.407Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M167.706 21.3461C167.706 20.4973 167.548 19.7993 167.232 19.2523C166.916 18.7049 166.451 18.4316 165.836 18.4316C165.465 18.4316 165.149 18.5208 164.887 18.6982C164.626 18.8761 164.414 19.1195 164.253 19.4288V23.0493C164.414 23.3587 164.626 23.5998 164.887 23.7728C165.149 23.9458 165.469 24.0322 165.849 24.0322C166.459 24.0322 166.921 23.796 167.235 23.3226C167.549 22.8496 167.706 22.2394 167.706 21.4918V21.3461ZM168.994 21.4954C168.994 22.5768 168.75 23.4492 168.262 24.112C167.774 24.7753 167.1 25.1065 166.241 25.1065C165.805 25.1065 165.422 25.0294 165.093 24.8743C164.764 24.7196 164.484 24.4874 164.253 24.178V27.8444H162.964V17.4617H163.952L164.155 18.4316C164.385 18.0715 164.673 17.7964 165.018 17.607C165.362 17.4175 165.764 17.323 166.221 17.323C167.098 17.323 167.779 17.6912 168.265 18.4281C168.752 19.1654 168.994 20.1394 168.994 21.3501V21.4954Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M170.682 24.9612H171.971V14.1483H170.682V24.9612Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M176.118 22.1814L176.347 23.1242H176.386L178.125 17.4617H179.558L176.582 26.1117C176.403 26.6155 176.147 27.0545 175.813 27.4285C175.48 27.803 175.03 27.9902 174.463 27.9902C174.358 27.9902 174.225 27.9773 174.064 27.9519C173.902 27.9264 173.778 27.9024 173.691 27.8792L173.822 26.8049C173.796 26.8 173.873 26.8049 174.054 26.8187C174.235 26.8325 174.35 26.8397 174.397 26.8397C174.672 26.8397 174.897 26.71 175.071 26.4514C175.246 26.1924 175.391 25.9058 175.509 25.592L175.817 24.8083L173.187 17.4617H174.626L176.118 22.1814Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M185.026 21.4954C185.026 22.2487 185.176 22.8541 185.478 23.3114C185.778 23.7688 186.236 23.9979 186.851 23.9979C187.239 23.9979 187.564 23.9043 187.826 23.7171C188.087 23.5299 188.303 23.2655 188.473 22.9232V19.4716C188.308 19.1524 188.091 18.8997 187.826 18.7125C187.559 18.5253 187.239 18.4316 186.864 18.4316C186.249 18.4316 185.789 18.7045 185.484 19.2496C185.178 19.7948 185.026 20.4951 185.026 21.3501V21.4954ZM183.737 21.3501C183.737 20.1438 183.986 19.1712 184.483 18.4316C184.98 17.6926 185.667 17.323 186.543 17.323C186.993 17.323 187.387 17.4189 187.728 17.6105C188.067 17.8022 188.351 18.0782 188.578 18.4388L188.735 17.4617H189.762V25.0027C189.762 25.9637 189.498 26.7019 188.97 27.2172C188.443 27.7325 187.681 27.9902 186.687 27.9902C186.347 27.9902 185.98 27.9403 185.585 27.8413C185.191 27.7419 184.843 27.6064 184.542 27.4357L184.869 26.3752C185.1 26.5138 185.38 26.6257 185.706 26.7113C186.033 26.7964 186.356 26.8397 186.674 26.8397C187.302 26.8397 187.759 26.6881 188.045 26.3854C188.33 26.0828 188.473 25.6218 188.473 25.0027V24.1571C188.242 24.4713 187.965 24.708 187.643 24.8676C187.32 25.0272 186.949 25.1065 186.53 25.1065C185.663 25.1065 184.98 24.774 184.483 24.1085C183.986 23.4429 183.737 22.5719 183.737 21.4954V21.3501Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M194.373 18.3973C193.941 18.3973 193.572 18.5894 193.268 18.9741C192.962 19.3584 192.775 19.8407 192.705 20.4211L192.718 20.4559H195.982V20.2762C195.982 19.7418 195.846 19.2951 195.576 18.9358C195.306 18.577 194.905 18.3973 194.373 18.3973ZM194.569 25.1065C193.584 25.1065 192.8 24.7593 192.218 24.0634C191.636 23.3681 191.344 22.4658 191.344 21.3568V21.0519C191.344 19.9842 191.644 19.0963 192.244 18.3866C192.843 17.6774 193.553 17.323 194.373 17.323C195.328 17.323 196.05 17.6275 196.538 18.2377C197.026 18.8475 197.27 19.6611 197.27 20.6774V21.5302H192.679L192.659 21.5649C192.673 22.2835 192.845 22.8746 193.176 23.3377C193.507 23.8009 193.972 24.0322 194.569 24.0322C195.005 24.0322 195.388 23.9663 195.717 23.8348C196.046 23.7033 196.331 23.5218 196.57 23.2905L197.074 24.1789C196.822 24.4441 196.488 24.6648 196.073 24.8418C195.659 25.0183 195.158 25.1065 194.569 25.1065Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M200.417 15.6523V17.4617H201.758V18.4735H200.417V23.027C200.417 23.3783 200.486 23.6257 200.623 23.7688C200.76 23.9123 200.942 23.9837 201.169 23.9837C201.243 23.9837 201.325 23.9747 201.414 23.956C201.504 23.9377 201.583 23.9146 201.653 23.8869L201.823 24.8226C201.727 24.9055 201.586 24.9737 201.401 25.0272C201.216 25.0798 201.03 25.1065 200.842 25.1065C200.319 25.1065 199.902 24.9389 199.593 24.6042C199.283 24.2694 199.128 23.7434 199.128 23.027V18.4735H198.003V17.4617H199.128V15.6523H200.417Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M205.047 15.6523V17.4617H206.389V18.4735H205.047V23.027C205.047 23.3783 205.116 23.6257 205.254 23.7688C205.391 23.9123 205.573 23.9837 205.8 23.9837C205.874 23.9837 205.956 23.9747 206.045 23.956C206.134 23.9377 206.214 23.9146 206.284 23.8869L206.454 24.8226C206.358 24.9055 206.217 24.9737 206.032 25.0272C205.847 25.0798 205.66 25.1065 205.473 25.1065C204.949 25.1065 204.533 24.9389 204.223 24.6042C203.914 24.2694 203.759 23.7434 203.759 23.027V18.4735H202.634V17.4617H203.759V15.6523H205.047Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M208.083 24.9612H209.498V17.4617H208.083V24.9612ZM208.083 15.5413H209.498V14.1483H208.083V15.5413Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M212.504 17.4617L212.596 18.5774C212.831 18.1798 213.126 17.8713 213.482 17.652C213.837 17.4327 214.242 17.323 214.695 17.323C215.458 17.323 216.049 17.5597 216.468 18.0331C216.886 18.507 217.096 19.238 217.096 20.2272V24.9612H215.807V20.2548C215.807 19.5942 215.684 19.1248 215.438 18.8475C215.191 18.5703 214.815 18.4316 214.309 18.4316C213.939 18.4316 213.611 18.5266 213.325 18.716C213.039 18.9055 212.811 19.164 212.641 19.4921V24.9612H211.353V17.4617H212.504Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M219.974 21.4954C219.974 22.2487 220.124 22.8541 220.425 23.3114C220.726 23.7688 221.184 23.9979 221.799 23.9979C222.187 23.9979 222.512 23.9043 222.773 23.7171C223.035 23.5299 223.251 23.2655 223.421 22.9232V19.4716C223.255 19.1524 223.039 18.8997 222.773 18.7125C222.507 18.5253 222.187 18.4316 221.812 18.4316C221.197 18.4316 220.737 18.7045 220.432 19.2496C220.126 19.7948 219.974 20.4951 219.974 21.3501V21.4954ZM218.685 21.3501C218.685 20.1438 218.934 19.1712 219.431 18.4316C219.928 17.6926 220.615 17.323 221.491 17.323C221.94 17.323 222.335 17.4189 222.675 17.6105C223.015 17.8022 223.299 18.0782 223.525 18.4388L223.682 17.4617H224.71V25.0027C224.71 25.9637 224.445 26.7019 223.918 27.2172C223.39 27.7325 222.629 27.9902 221.635 27.9902C221.295 27.9902 220.928 27.9403 220.533 27.8413C220.138 27.7419 219.791 27.6064 219.49 27.4357L219.817 26.3752C220.048 26.5138 220.327 26.6257 220.654 26.7113C220.981 26.7964 221.304 26.8397 221.622 26.8397C222.25 26.8397 222.707 26.6881 222.993 26.3854C223.278 26.0828 223.421 25.6218 223.421 25.0027V24.1571C223.189 24.4713 222.913 24.708 222.59 24.8676C222.267 25.0272 221.897 25.1065 221.478 25.1065C220.611 25.1065 219.928 24.774 219.431 24.1085C218.934 23.4429 218.685 22.5719 218.685 21.4954V21.3501Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M230.014 24.9612H231.429V17.4617H230.014V24.9612ZM230.014 15.5413H231.429V14.1483H230.014V15.5413Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M234.985 15.6523V17.4617H236.326V18.4735H234.985V23.027C234.985 23.3783 235.054 23.6257 235.191 23.7688C235.329 23.9123 235.51 23.9837 235.737 23.9837C235.811 23.9837 235.893 23.9747 235.982 23.956C236.072 23.9377 236.151 23.9146 236.221 23.8869L236.391 24.8226C236.295 24.9055 236.155 24.9737 235.969 25.0272C235.784 25.0798 235.597 25.1065 235.41 25.1065C234.887 25.1065 234.471 24.9389 234.161 24.6042C233.851 24.2694 233.697 23.7434 233.697 23.027V18.4735H232.571V17.4617H233.697V15.6523H234.985Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M242.239 21.4954C242.239 22.2536 242.385 22.8599 242.677 23.315C242.969 23.7701 243.423 23.9979 244.038 23.9979C244.421 23.9979 244.744 23.9052 245.006 23.7207C245.267 23.5357 245.481 23.2767 245.647 22.9441V19.4435C245.481 19.1342 245.266 18.8881 245.002 18.7058C244.739 18.523 244.421 18.4317 244.051 18.4317C243.432 18.4317 242.975 18.7022 242.68 19.2429C242.386 19.7837 242.239 20.4857 242.239 21.3501V21.4954ZM240.95 21.3501C240.95 20.1438 241.193 19.1712 241.68 18.4317C242.166 17.6926 242.847 17.323 243.724 17.323C244.138 17.323 244.505 17.4037 244.826 17.5655C245.147 17.7273 245.42 17.9632 245.647 18.2725V14.1483H246.935V24.9612H245.882L245.732 24.0394C245.5 24.3902 245.217 24.6563 244.882 24.8364C244.546 25.0165 244.155 25.1065 243.711 25.1065C242.847 25.1065 242.171 24.7753 241.683 24.112C241.195 23.4492 240.95 22.5768 240.95 21.4954V21.3501Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M259.997 17.4617L260.089 18.5774C260.324 18.1798 260.619 17.8713 260.975 17.652C261.33 17.4327 261.735 17.323 262.188 17.323C262.952 17.323 263.542 17.5597 263.961 18.0331C264.379 18.507 264.589 19.238 264.589 20.2272V24.9612H263.3V20.2548C263.3 19.5942 263.177 19.1248 262.93 18.8475C262.684 18.5703 262.308 18.4316 261.802 18.4316C261.432 18.4316 261.104 18.5266 260.818 18.716C260.532 18.9055 260.305 19.164 260.135 19.4921V24.9612H258.846V17.4617H259.997Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M269.2 18.3973C268.769 18.3973 268.4 18.5894 268.095 18.9741C267.789 19.3584 267.602 19.8407 267.532 20.4211L267.545 20.4559H270.809V20.2762C270.809 19.7418 270.674 19.2951 270.404 18.9358C270.133 18.577 269.732 18.3973 269.2 18.3973ZM269.396 25.1065C268.411 25.1065 267.627 24.7593 267.045 24.0634C266.463 23.3681 266.172 22.4658 266.172 21.3568V21.0519C266.172 19.9842 266.472 19.0963 267.071 18.3866C267.671 17.6774 268.38 17.323 269.2 17.323C270.155 17.323 270.877 17.6275 271.365 18.2377C271.853 18.8475 272.098 19.6611 272.098 20.6774V21.5302H267.506L267.487 21.5649C267.5 22.2835 267.672 22.8746 268.004 23.3377C268.335 23.8009 268.799 24.0322 269.396 24.0322C269.832 24.0322 270.215 23.9663 270.544 23.8348C270.874 23.7033 271.158 23.5218 271.398 23.2905L271.901 24.1789C271.649 24.4441 271.315 24.6648 270.901 24.8418C270.487 25.0183 269.985 25.1065 269.396 25.1065Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M273.661 24.9612H274.949V23.5472H273.661V24.9612Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M277.498 15.5226H275.968V20.0279H275.325V15.5226H273.798V14.9854H277.498V15.5226Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M279.021 14.9854L280.537 19.0825H280.557L282.073 14.9854H282.897V20.0279H282.253V18.0331L282.319 15.9831L282.302 15.9795L280.76 20.0279H280.331L278.796 15.9902L278.779 15.9933L278.841 18.0331V20.0279H278.198V14.9854H279.021Z" fill="#FFFFFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M256.699 21.4838C256.699 23.7724 254.949 25.6272 252.789 25.6272C250.63 25.6272 248.879 23.7724 248.879 21.4838C248.879 19.1957 250.63 17.3409 252.789 17.3409C254.949 17.3409 256.699 19.1957 256.699 21.4838Z" fill="#EB8F2D"/>
  </svg>
);

export default MainLogo;
