import React, { useEffect, useRef, useState, useContext } from 'react';
import { snowflakeFetch } from '../../core/service/api';
import { Context } from '../../core/store';

import styles from './input-trade.scss';

let eventAttached = false;

const InputTrade = ({
  onFilter,
  selectedTrades,
  showTradesDropdown,
  preSelectedTradesVal,
  setPreSelectedTradesVal
}) => {
  const [, dispatch] = useContext(Context);
  const [input, setInput] = useState('');
  const [loadingOptions, setLoadingOptions] = useState(true);
  const [options, setOptions] = useState([]);

  const permitTradeRef = useRef(null);
  const dropdownRef = useRef(null);
  const toggleRef = useRef(null)

  const triggerFilter = () => {
    const state = {
      preSelectedTrades: preSelectedTradesVal,
      selectedTrades: preSelectedTradesVal,
    };

    dispatch({
      type: 'set_filter-trades',
      payload: state,
    });

    dispatch({
      type: 'set_filter-trades-dropdown',
      payload: false,
    });

    onFilter(preSelectedTradesVal);
  };

  const displayOptions = () => {
    const filteredOptions = options.filter((x) => (
      input.trim().length === 0 || (x.label
        && x.label.toLowerCase().includes(input.toLocaleLowerCase()))
    ));

    if (filteredOptions.length === 0) {
      return (
        <p className={styles.empty}>Permit trade not found</p>
      );
    }

    return (
      <>
        <ul
          id="p_type-drop"
          role="listbox"
          aria-label="Filter Permit Trades"
        >
          {
            filteredOptions.map((option, index) => (
              <li key={`permit-trade-${index}`}>
                <label htmlFor={`permit-trade-${index}`}>
                  <input
                    type="checkbox"
                    name="permit-trade"
                    ref={permitTradeRef}
                    id={`permit-trade-${index}`}
                    checked={preSelectedTradesVal.findIndex((x) => x.value === option.value) > -1}
                    onChange={(e) => {
                      const _index = preSelectedTradesVal.findIndex((x) => x.value === e.target.value);
                      if (e.target.checked && _index === -1) {
                        setPreSelectedTradesVal([...preSelectedTradesVal, option]);
                      } else if (!e.target.checked && _index > -1) {
                        setPreSelectedTradesVal(preSelectedTradesVal.filter((x) => (
                          x.value !== e.target.value
                        )));
                      }
                    }}
                    value={option.value}
                  />
                  <span>{ option.label }</span>
                </label>
              </li>
            ))
          }
        </ul>
        <div className={styles.trigger}>
          <button
            type="button"
            className={styles.typeFilterBtn}
            onClick={triggerFilter}
          >
            Apply
          </button>
        </div>
      </>
    );
  };

  const handleClick = (e) => {
    if (dropdownRef.current
      && !dropdownRef.current.contains(e.target)
      && !permitTradeRef.current.contains(e.target)
      && !toggleRef.current.contains(e.target)) {
      setPreSelectedTradesVal([]);
      dispatch({
        type: 'set_filter-trades-dropdown',
        payload: false,
      });
    }
  };

  const handleDesktopEvents = () => {
    if (window.innerWidth > 800 && !eventAttached) {
      document.addEventListener('mousedown', handleClick, false);
      eventAttached = true;

      return () => {
        document.removeEventListener('mousedown', handleClick, false);
      };
    } else {
      document.removeEventListener('mousedown', handleClick, false);
    }
  }

  useEffect(() => {
    snowflakeFetch('permit-trades', null, ({ data }) => {
      const newOptions = data.data.map((item) => ({
        value: item.TRADE || 'undefined',
        label: item.TRADE || 'No Permit Trade',
      }));

      setOptions(newOptions);
      setLoadingOptions(false);
    }, false);

    handleDesktopEvents();
  }, []);

  window.addEventListener('resize', handleDesktopEvents);

  return (
    <div className={styles.inputTrade}>
      <button
        type="button"
        ref={toggleRef}
        className={styles.inputTradeBtn}
        aria-haspopup="listbox"
        aria-label="Filter Permit Trades"
        id="p_type-drop-trigger"
        onClick={() => {
          dispatch({
            type: 'set_filter-trades-dropdown',
            payload: !showTradesDropdown,
          });
        }}
      >
        <div>
          {
            selectedTrades.length === 0
              ? 'Permit Trades'
              : `${selectedTrades.length} permit trades${selectedTrades.length !== 1 ? 's' : ''} selected`
          }
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path d="M12 21l-12-18h24z" />
          </svg>
        </div>
      </button>
      {
        showTradesDropdown
          ? (
            <div className={styles.inputTradeDropdown} ref={dropdownRef}>
              {
                loadingOptions
                  ? (<p className={styles.loadingText}>Loading Permit Trades...</p>)
                  : displayOptions()
              }
            </div>
          )
          : null
      }
    </div>
  );
};

export default InputTrade;
