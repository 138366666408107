import React, { useContext } from "react";
import classnames from "classnames";

import { Context } from "../../core/store";

import styles from "./map.scss";

const SEARCH_STRING = "200 S Wacker Dr Chicago, IL";

const NoDataOverlay = () => {
  const [state] = useContext(Context);
  const { widgetOpen } = state;

  const handleSearchForYouClick = () => {
    const searchForm =
      window.innerWidth > 800
        ? document.getElementById("desktop-search")
        : document.getElementById("mobile-search");

    // Assume there is only one input within the form.
    const [searchInput] = searchForm.getElementsByTagName("input");
    const [searchButton] = searchForm.getElementsByTagName("button");

    if (searchForm && searchInput) {
      searchInput.value = SEARCH_STRING;
      searchButton.click();
    }
  };

  return (
    <div className={styles.noDataOverlay}>
      <div
        className={classnames(styles.noDataContainer, {
          [styles.widgetOpen]: widgetOpen,
        })}
      >
        <p>There were no permits found in this location.</p>
        <p>Try another search or let us search for you</p>
        <button className={styles.noDataBtn} onClick={handleSearchForYouClick}>
          Go to {SEARCH_STRING}
        </button>
      </div>
    </div>
  );
};

export default NoDataOverlay;
