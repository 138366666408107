import dayjs from "dayjs";
import mapboxgl from "mapbox-gl";
import { snowflakeFetch } from "../../core/service/api";

// create a list of permit for the map marker pop up
const mapPermits = (permits) =>
  permits
    .map((permit) => {
      const { PERMITID, PERMITNUMBER, PERMITCLASS, ISSUEDATE } = permit;

      return `
        <li>
          <button class="permit-btn" data-id="${PERMITID}" id="button-${PERMITID}" type="button">
            <div>
              <h5>Permit No. ${PERMITNUMBER}</h5>
              <div class="meta">
                <span>Class:</span>
                <span>${PERMITCLASS || "-"}</span>
              </div>
              <div class="meta">
                <span>Created on:</span>
                <span>${ISSUEDATE}</span>
              </div>
            </div>
          </button>
        </li>
      `;
    })
    .join("");

// create mapbox data source
const createDataSource = (dataSource, otherConfig = {}, mapBox) => {
  if (!mapBox.getSource("permits")) {
    mapBox.addSource("permits", {
      type: "geojson",
      data: dataSource,
      ...otherConfig,
    });
  }
};

// destroy mapbox data source
const destroyDataSource = (mapBox) => {
  if (mapBox.getSource("permits")) {
    mapBox.removeSource("permits");
  }
};

// create marker layers
let markerPopup;
let popupDesktop;

const createMarkerLayers = (mapBox, dispatch, state, Popup) => {
  window.addEventListener("resize", () => {
    if (window.innerWidth < 801) {
      dispatch({
        type: "set_permit",
        payload: null,
      });

      dispatch({
        type: "set_popup",
        payload: null,
      });

      if (popupDesktop != undefined) {
        popupDesktop.remove();
      }
    } else {
      dispatch({
        type: "set_permit-panel",
        payload: false,
      });
    }

    if (markerPopup != undefined && window.innerWidth === 800) {
      markerPopup.remove();
    }
  });

  // generate layers
  if (!mapBox.getLayer("clusters")) {
    mapBox.addLayer({
      id: "clusters",
      type: "circle",
      source: "permits",
      filter: ["has", "point_count"],
      paint: {
        "circle-color": [
          "step",
          ["get", "point_count"],
          "#93D567",
          1000,
          "#EFCC3F",
          2500,
          "#F3984E",
        ],
        "circle-stroke-width": 6,
        "circle-stroke-color": [
          "step",
          ["get", "point_count"],
          "rgba(147, 213, 103, 0.5)",
          1000,
          "rgba(239, 204, 63, 0.5)",
          2500,
          "rgba(243, 152, 78, 0.5)",
        ],
        "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
      },
    });
  }

  if (!mapBox.getLayer("cluster-count")) {
    mapBox.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: "permits",
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 14,
      },
    });
  }

  if (!mapBox.getLayer("unclustered-point")) {
    mapBox.addLayer({
      id: "unclustered-point",
      type: "circle",
      source: "permits",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-color": "#09617D",
        "circle-radius": 8,
        "circle-stroke-width": 2,
        "circle-stroke-color": "#FFFFFF",
      },
    });
  }

  // bind click events
  mapBox.on("click", "clusters", (e) => {
    const features = mapBox.queryRenderedFeatures(e.point, {
      layers: ["clusters"],
    });

    const clusterID = features[0].properties.cluster_id;

    mapBox
      .getSource("permits")
      .getClusterExpansionZoom(clusterID, (err, zoom) => {
        if (err) {
          return;
        }

        mapBox.easeTo({
          center: features[0].geometry.coordinates,
          zoom,
        });
      });
  });

  mapBox.on("click", "unclustered-point", (e) => {
    dispatch({
      type: "set_loading",
      payload: true,
    });

    dispatch({
      type: "set_loader-text",
      payload: "Loading Permit Details",
    });

    const coordinates = e.features[0].geometry.coordinates.slice();
    const { properties } = e.features[0];

    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    snowflakeFetch(
      "permits/group",
      {
        lat: properties.lat,
        lng: properties.lng,
      },
      (res) => {
        if (res.statusText === "OK") {
          const permitTypes = state.selectedTypes.map((type) => type.value);
          const { data: resData } = res.data;
          let permitData = permitTypes.length > 0 ? [] : resData;

          if (permitTypes.length > 0) {
            resData.forEach((data) => {
              permitTypes.forEach((type, index) => {
                if (data.PERMITTYPE === permitTypes[index]) {
                  permitData.push(data);
                }
              });
            });
          }

          // Maybe animate to the
          // mapBox.setCenter(coordinates);

          // Remove duplicate entries.
          const filteredPermits = permitData.reduce((acc, current) => {
            const x = acc.find((item) => item.PERMITID === current.PERMITID);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);

          if (window.innerWidth > 800) {
            popupDesktop = new Popup({
              closeButton: false,
              anchor: "top-left",
              offset: [5, 5],
            })
              .setMaxWidth("400px")
              .setLngLat(coordinates).setHTML(`
                <h4>${properties.address || "No address stated"}</h4>
                <ul class="permits">
                  ${mapPermits(filteredPermits)}
                </ul>
              `);

            dispatch({
              type: "set_popup",
              payload: popupDesktop,
            });

            let el = document.createElement("div");
            el.className = "markerPopup";

            markerPopup = new mapboxgl.Marker(el)
              .setLngLat(coordinates)
              .addTo(mapBox);

            popupDesktop.on("open", () => {
              Array.from(document.querySelectorAll(".permit-btn")).forEach(
                (btn) => {
                  btn.addEventListener("click", (ev) => {
                    ev.preventDefault();

                    const permitID = btn.getAttribute("data-id");
                    const permit = res.data.data.find(
                      (point) => point.PERMITID == permitID
                    );

                    if (document.querySelector(".permit-btn.is-active")) {
                      document
                        .querySelector(".permit-btn.is-active")
                        .classList.remove("is-active");
                    }

                    btn.classList.add("is-active");

                    dispatch({
                      type: "set_permit",
                      payload: permit,
                    });

                    dispatch({
                      type: "set_side-widget",
                      payload: true,
                    });
                  });
                }
              );
            });

            popupDesktop.on("close", () => {
              dispatch({
                type: "set_permit",
                payload: null,
              });

              dispatch({
                type: "set_popup",
                payload: null,
              });

              if (markerPopup != undefined) {
                markerPopup.remove();
              }
            });

            popupDesktop.addTo(mapBox);
          } else {
            if (markerPopup != undefined) {
              markerPopup.remove();
            }

            let el = document.createElement("div");
            el.className = "markerPopup";

            markerPopup = new mapboxgl.Marker(el)
              .setLngLat(coordinates)
              .addTo(mapBox);

            const data = {
              address: properties.address || "No address stated",
              permits: filteredPermits,
            };

            dispatch({
              type: "set_permit-panel",
              payload: true,
            });

            dispatch({
              type: "set_mobile-panel-data",
              payload: data,
            });

            if (!state.permitMobilePanel) {
              document.addEventListener("mousedown", () => {
                markerPopup.remove();
              });
            }
          }

          dispatch({
            type: "set_loading",
            payload: false,
          });

          return () => {
            document.removeEventListener("mousedown", () => {
              if (!state.permitMobilePanel) {
                markerPopup.remove();
              }
            });
          };
        }
      }
    );
  });

  // bind styling events
  mapBox.on("mouseenter", "clusters", () => {
    mapBox.getCanvas().style.cursor = "pointer";
  });

  mapBox.on("mouseleave", "clusters", () => {
    mapBox.getCanvas().style.cursor = "";
  });

  mapBox.on("mouseenter", "unclustered-point", () => {
    mapBox.getCanvas().style.cursor = "pointer";
  });

  mapBox.on("mouseleave", "unclustered-point", () => {
    mapBox.getCanvas().style.cursor = "";
  });
};

// destroy marker layers
const destroyMarkerLayers = (mapBox) => {
  if (mapBox.getLayer("clusters")) {
    mapBox.removeLayer("clusters");
  }

  if (mapBox.getLayer("cluster-count")) {
    mapBox.removeLayer("cluster-count");
  }

  if (mapBox.getLayer("unclustered-point")) {
    mapBox.removeLayer("unclustered-point");
  }

  if (markerPopup != undefined) {
    markerPopup.remove();
  }

  if (popupDesktop != undefined) {
    popupDesktop.remove();
  }
};

// create heatmap layers
const createHeatMapLayers = (mapBox) => {
  if (!mapBox.getLayer("permit-heat")) {
    mapBox.addLayer(
      {
        id: "permit-heat",
        type: "heatmap",
        source: "permits",
        maxzoom: 15,
        paint: {
          // increase weight as diameter breast height increases
          "heatmap-weight": {
            property: "dbh",
            type: "exponential",
            stops: [
              [1, 0],
              [62, 1],
            ],
          },
          // increase intensity as zoom level increases
          "heatmap-intensity": {
            stops: [
              [11, 1],
              [15, 3],
            ],
          },
          // assign color values be applied to points depending on their density
          "heatmap-color": [
            "interpolate",
            ["linear"],
            ["heatmap-density"],
            0,
            "rgba(0, 0, 255, 0)",
            0.1,
            "royalblue",
            0.3,
            "cyan",
            0.5,
            "lime",
            0.7,
            "yellow",
            1,
            "hsl(0, 96%, 72%)",
          ],
          // increase radius as zoom increases
          "heatmap-radius": {
            stops: [
              [11, 15],
              [15, 20],
            ],
          },
          // decrease opacity to transition into the circle layer
          "heatmap-opacity": {
            default: 1,
            stops: [
              [14, 1],
              [15, 0],
            ],
          },
        },
      },
      "waterway-label"
    );
  }

  if (!mapBox.getLayer("permit-point")) {
    mapBox.addLayer(
      {
        id: "permit-point",
        type: "circle",
        source: "permits",
        minzoom: 14,
        paint: {
          // increase the radius of the circle as the zoom level and dbh value increases
          "circle-radius": {
            property: "dbh",
            type: "exponential",
            stops: [
              [{ zoom: 15, value: 1 }, 5],
              [{ zoom: 15, value: 62 }, 10],
              [{ zoom: 22, value: 1 }, 20],
              [{ zoom: 22, value: 62 }, 50],
            ],
          },
          "circle-color": {
            property: "dbh",
            type: "exponential",
            stops: [
              [0, "rgba(236,222,239,0)"],
              [10, "rgb(236,222,239)"],
              [20, "rgb(208,209,230)"],
              [30, "rgb(166,189,219)"],
              [40, "rgb(103,169,207)"],
              [50, "rgb(28,144,153)"],
              [60, "rgb(1,108,89)"],
            ],
          },
          "circle-stroke-color": "white",
          "circle-stroke-width": 1,
          "circle-opacity": {
            stops: [
              [14, 0],
              [15, 1],
            ],
          },
        },
      },
      "waterway-label"
    );
  }
};

// destroy heatmap layers
const destroyHeatMapLayers = (mapBox) => {
  if (mapBox.getLayer("permit-heat")) {
    mapBox.removeLayer("permit-heat");
  }

  if (mapBox.getLayer("permit-point")) {
    mapBox.removeLayer("permit-point");
  }
};

/**
 * 
 * @param {[
    map.getBounds().getSouthWest().lng,
    map.getBounds().getSouthWest().lat,
    map.getBounds().getNorthEast().lng,
    map.getBounds().getNorthEast().lat,
  ]} boundingBox 
 * @param {boolean} silentLoad 
 */
export const searchLocation = (boundingBox, silentLoad = false) => {
  if (!silentLoad) {
    dispatch({
      type: "set_loader-text",
      payload: "Loading Permits",
    });

    dispatch({
      type: "set_loading",
      payload: true,
    });
  }

  const params = {
    bounds: boundingBox.join(","),
    type:
      selectedTypes.length > 0
        ? selectedTypes.map((type) => type.label).join(",")
        : null,
    trade:
      selectedTrades.length > 0
        ? selectedTrades.map((trade) => trade.label).join(",")
        : null,
    dateType: dateType ? dateType.value : null,
    dateRange: dateRange
      ? `${dayjs(dateRange.from).format("YYYY-MM-DD")},${dayjs(
          dateRange.to
        ).format("YYYY-MM-DD")}`
      : null,
  };

  snowflakeFetch("permits", params, (res) => {
    if (res.statusText === "OK") {
      dispatch({
        type: "set_data",
        payload: {
          ...res.data,
          searchKey: "",
        },
      });

      dispatch({
        type: "set_loading",
        payload: false,
      });
    }
  });
};

export {
  mapPermits,
  createDataSource,
  destroyDataSource,
  createMarkerLayers,
  destroyMarkerLayers,
  createHeatMapLayers,
  destroyHeatMapLayers,
};

export default null;
