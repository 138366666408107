import React, { useState, useContext } from "react";
import dayjs from "dayjs";
import mapboxgl from "mapbox-gl";
import fuzzysearch from "fuzzysearch";
import { snowflakeFetch } from "../../core/service/api";
import { Context } from "../../core/store";

import Search, { getLocationByString } from "../search/search";
import InputType from "../input-type/input-type";
import InputTrade from "../input-trade/input-trade";
import InputDate from "../input-date/input-date";
import SubLogo from "./sublogo";
import MainLogo from "./main-logo";
import MenuOverlay from "./menu-overlay";
import { mapPermits } from "../map/map-functions";

import styles from "./navbar.scss";

const roundLatLong = (value) => {
  const multiplier = 1000;
  return Math.round((value + Number.EPSILON) * multiplier) / multiplier;
};

const numberWithCommas = (x) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const Navbar = () => {
  const [dateRangeVal, setDateRangeVal] = useState(null);
  const [dateRangeTypeVal, setDateRangeTypeVal] = useState(null);

  const [preSelectedTypesVal, setPreSelectedTypesVal] = useState([]);
  const [preSelectedTradesVal, setPreSelectedTradesVal] = useState([]);

  const [state, dispatch] = useContext(Context);
  const {
    dateType,
    dateRange,
    map,
    activePopup,
    filterOpen,
    data: globalData,
    selectedTypes,
    showTypesDropdown,
    showDateRange,
    showDateDropdown,
    menuOverlay,
    showAllPermits,
    showPermitDetails,
    selectedTrades,
    showTradesDropdown,
  } = state;

  const closePopup = () => {
    dispatch({
      type: "set_permit",
      payload: null,
    });

    dispatch({
      type: "set_popup",
      payload: null,
    });
  };

  const openPopup = (coordinates, address, permitData) => {
    // Remove duplicate entries.
    const filteredPermits = permitData.reduce((acc, current) => {
      const x = acc.find((item) => item.PERMITID === current.PERMITID);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    const popupDesktop = new mapboxgl.Popup({
      closeButton: false,
      anchor: "top-left",
      offset: [5, 5],
    })
      .setMaxWidth("400px")
      .setLngLat(coordinates).setHTML(`
        <div class="markerPopup"></div>
        <h4>${address || "No address stated"}</h4>
        <ul class="permits">
          ${mapPermits(filteredPermits)}
        </ul>
      `);

    dispatch({
      type: "set_popup",
      payload: popupDesktop,
    });

    popupDesktop.on("open", () => {
      Array.from(document.querySelectorAll(".permit-btn")).forEach((btn) => {
        btn.addEventListener("click", (ev) => {
          ev.preventDefault();

          const permitID = btn.getAttribute("data-id");
          const permit = filteredPermits.find(
            (point) => point.PERMITID == permitID
          );

          if (document.querySelector(".permit-btn.is-active")) {
            document
              .querySelector(".permit-btn.is-active")
              .classList.remove("is-active");
          }

          btn.classList.add("is-active");

          dispatch({
            type: "set_permit",
            payload: permit,
          });

          dispatch({
            type: "set_side-widget",
            payload: true,
          });
        });
      });
    });

    popupDesktop.on("close", closePopup);

    popupDesktop.addTo(map);
  };

  const loadPermitDetails = (permit) => {
    if (!permit) return;
    dispatch({
      type: "set_loader-text",
      payload: "Loading Permit Details",
    });

    const { geometry, properties } = permit;
    const { address } = properties;
    const { coordinates } = geometry;
    const [lng, lat] = coordinates;

    snowflakeFetch("permits/group", { lat, lng }, (res) => {
      const permitTypes = selectedTypes.map((type) => type.value);
      const { data } = res.data;
      const permitData = permitTypes.length > 0 ? [] : data;

      if (permitTypes.length > 0) {
        data.forEach((el) => {
          permitTypes.forEach((type, index) => {
            if (el.PERMITTYPE === permitTypes[index]) {
              permitData.push(el);
            }
          });
        });
      }

      dispatch({
        type: "set_loading",
        payload: false,
      });

      if (res.statusText === "OK") {
        if (window.innerWidth > 800) {
          openPopup(coordinates, address, permitData);
        } else {
          // Hook up mobile Popup open.
        }
      }
    });
  };

  const getLocationData = (place_name, givenPoints, center, properties) => {
    dispatch({
      type: "set_loading",
      payload: true,
    });

    dispatch({
      type: "set_loader-text",
      payload: "Loading Permits",
    });

    const params = {
      bounds: givenPoints.join(","),
      type:
        selectedTypes.length > 0
          ? selectedTypes.map((type) => type.label).join(",")
          : null,
      trade:
        selectedTrades.length > 0
          ? selectedTrades.map((trade) => trade.label).join(",")
          : null,
      dateType: dateType ? dateType.value : null,
      dateRange: dateRange
        ? `${dayjs(dateRange.from).format("YYYY-MM-DD")},${dayjs(
            dateRange.to
          ).format("YYYY-MM-DD")}`
        : null,
    };

    snowflakeFetch("permits", params, (res) => {
      if (res.statusText === "OK") {
        const { data } = res;

        dispatch({
          type: "set_data",
          payload: {
            ...data,
            searchKey: place_name,
          },
        });

        dispatch({
          type: "set_loading",
          payload: false,
        });
      }
    });
  };

  /**
   *
   * @param {String} value
   *
   * @returns void
   */
  const handleSearchSubmit = async (value) => {
    if (value === "" || !value) return;

    // Remove active popup if there is one.
    if (activePopup) {
      activePopup.remove();
    }

    const searchQuery = value;
    const searchResults = await getLocationByString(searchQuery);
    const result =
      searchResults.find((el) => el.label === searchQuery) || searchResults[0];

    /**
     *
     * If there is a results from mapboxSearch in getLocationByString then recenter the map.
     * If result.data has a bbox use that to set map position, otherwise go to specific coordinate.
     *
     */
    if (result) {
      dispatch({
        type: "set_loading",
        payload: true,
      });

      dispatch({
        type: "set_loader-text",
        payload: "Loading Location",
      });

      const { bbox, center, place_name, properties } = result.data;
      if (bbox) {
        // Animate to a region.
        map.fitBounds(bbox);
        // Search immediately.
        getLocationData(place_name, bbox);
      } else {
        // Check if given coordinates have permits at them
        // so we can load popup faster.

        // Animate to a specific location.
        map.flyTo({
          center,
          essential: true,
          zoom: 18,
        });

        // Search for permits in the area.
        map.once("zoomend", () => {
          const newPoints = [
            map.getBounds().getSouthWest().lng,
            map.getBounds().getSouthWest().lat,
            map.getBounds().getNorthEast().lng,
            map.getBounds().getNorthEast().lat,
          ];
          getLocationData(place_name, newPoints, center, properties);
        });
      }
    }
  };

  return (
    <div className={styles.navbar}>
      {menuOverlay ? <MenuOverlay styles={styles} /> : null}
      <div className={styles.navbarTop}>
        <button
          className={styles.logo}
          type="button"
          aria-label="Burnham Permit Mapper"
          onClick={() => {
            if (activePopup) {
              activePopup.remove();

              dispatch({
                type: "set_popup",
                payload: null,
              });
            }

            dispatch({
              type: "set_permit",
              payload: null,
            });
          }}
        >
          <svg
            className={styles.icon}
            width="25"
            height="30"
            viewBox="0 0 25 30"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.97701 24.1028V0.0579987H0.977005V29.058H6.97701V24.1028"
              fill="#EB8F2D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.7 13.2978C22.1971 12.0788 23.609 10.0888 23.609 7.24472C23.609 1.84303 19.901 0.0579987 14.4214 0.0579987H9.97701V5.00935H13.132C15.3881 5.00935 17.4839 5.58081 17.4839 8.3426C17.4839 10.7806 15.8294 11.7931 13.6134 11.7931H9.97701V16.1396H14.0987C16.7984 16.1396 18.6532 17.3206 18.6532 20.2842C18.6532 23.2867 16.4757 24.1028 13.9785 24.1028H9.97701V29.058H14.825C20.0212 29.058 24.977 26.5392 24.977 20.6499C24.977 17.0356 23.2054 14.3142 19.7 13.2978"
              fill="#EB8F2D"
            />
          </svg>

          <MainLogo className={styles.mainLogo} />
        </button>

        <div className={styles.searchDesktop}>
          <div className={styles.searchDesktopInputs}>
            <Search id="desktop-search" onSubmit={handleSearchSubmit} />
            <InputType
              onFilter={() => {
                const newPoints = [
                  map.getBounds().getSouthWest().lng,
                  map.getBounds().getSouthWest().lat,
                  map.getBounds().getNorthEast().lng,
                  map.getBounds().getNorthEast().lat,
                ];

                getLocationData("", newPoints);
              }}
              selectedTypes={selectedTypes}
              showTypesDropdown={showTypesDropdown}
              preSelectedTypesVal={preSelectedTypesVal}
              setPreSelectedTypesVal={setPreSelectedTypesVal}
            />
            <InputTrade
              onFilter={() => {
                const newPoints = [
                  map.getBounds().getSouthWest().lng,
                  map.getBounds().getSouthWest().lat,
                  map.getBounds().getNorthEast().lng,
                  map.getBounds().getNorthEast().lat,
                ];

                getLocationData("", newPoints);
              }}
              selectedTrades={selectedTrades}
              showTradesDropdown={showTradesDropdown}
              preSelectedTradesVal={preSelectedTradesVal}
              setPreSelectedTradesVal={setPreSelectedTradesVal}
            />
            <InputDate
              showDateRange={showDateRange}
              showDateDropdown={showDateDropdown}
              dateRangeVal={dateRangeVal}
              setDateRangeVal={() => {
                const newPoints = [
                  map.getBounds().getSouthWest().lng,
                  map.getBounds().getSouthWest().lat,
                  map.getBounds().getNorthEast().lng,
                  map.getBounds().getNorthEast().lat,
                ];

                getLocationData("", newPoints);
              }}
              dateRangeTypeVal={dateRangeTypeVal}
              setDateRangeTypeVal={() => {
                const newPoints = [
                  map.getBounds().getSouthWest().lng,
                  map.getBounds().getSouthWest().lat,
                  map.getBounds().getNorthEast().lng,
                  map.getBounds().getNorthEast().lat,
                ];

                getLocationData("", newPoints);
              }}
            />
            {globalData && (
              <p className={styles.totalPermits}>
                {`${numberWithCommas(globalData.totalPermits || 0)} permit${
                  globalData.totalPermits !== 1 ? "s" : ""
                } found`}
              </p>
            )}
          </div>
          <div>
            <SubLogo className={styles.subLogo} />
          </div>
        </div>
        <div className={styles.searchMobile}>
          <button
            type="button"
            className={
              menuOverlay
                ? styles.toggleBtn + " " + styles.open
                : styles.toggleBtn
            }
            onClick={() => {
              dispatch({
                type: "set_menu-overlay",
                payload: !menuOverlay,
              });
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
      <div
        className={
          showAllPermits || showPermitDetails
            ? styles.navbarBottom + " " + styles.open
            : styles.navbarBottom
        }
      >
        {showAllPermits && (
          <>
            {showPermitDetails ? (
              <button
                className={styles.returnBtn}
                onClick={() => {
                  dispatch({
                    type: "set_permit-details-view-all",
                    payload: false,
                  });
                  dispatch({
                    type: "set_permit-panel",
                    payload: true,
                  });
                }}
              >
                <svg
                  width="19"
                  height="15"
                  viewBox="0 0 19 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.94592 8.35982L15.6185 8.35982C15.6831 8.36044 15.7407 8.40072 15.7635 8.46123C15.7862 8.52173 15.7695 8.59003 15.7214 8.63315L10.3343 13.376C9.98225 13.7081 9.95638 14.2595 10.2758 14.6231C10.5951 14.9867 11.145 15.0319 11.5193 14.7253L18.4932 8.5851C18.8154 8.30078 19 7.89164 19 7.46179C19 7.03193 18.8154 6.6228 18.4932 6.33847L11.5206 0.199564C11.1461 -0.103114 10.5996 -0.0564852 10.2817 0.305278C9.96382 0.667041 9.98755 1.21526 10.3355 1.54816L15.7226 6.29105C15.7708 6.33417 15.7875 6.40247 15.7647 6.46297C15.7419 6.52348 15.6843 6.56376 15.6197 6.56439L0.874495 6.56439C0.38784 6.57722 0 6.97564 0 7.46272C0 7.94981 0.38784 8.34823 0.874495 8.36106L4.94592 8.35982Z"
                    fill="#f75c06"
                  />
                </svg>
                List
              </button>
            ) : (
              <button
                className={styles.returnBtn}
                onClick={() => {
                  dispatch({
                    type: "set_permit-view-all",
                    payload: false,
                  });
                  dispatch({
                    type: "set_permit-panel",
                    payload: true,
                  });
                }}
              >
                <svg
                  width="19"
                  height="15"
                  viewBox="0 0 19 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.94592 8.35982L15.6185 8.35982C15.6831 8.36044 15.7407 8.40072 15.7635 8.46123C15.7862 8.52173 15.7695 8.59003 15.7214 8.63315L10.3343 13.376C9.98225 13.7081 9.95638 14.2595 10.2758 14.6231C10.5951 14.9867 11.145 15.0319 11.5193 14.7253L18.4932 8.5851C18.8154 8.30078 19 7.89164 19 7.46179C19 7.03193 18.8154 6.6228 18.4932 6.33847L11.5206 0.199564C11.1461 -0.103114 10.5996 -0.0564852 10.2817 0.305278C9.96382 0.667041 9.98755 1.21526 10.3355 1.54816L15.7226 6.29105C15.7708 6.33417 15.7875 6.40247 15.7647 6.46297C15.7419 6.52348 15.6843 6.56376 15.6197 6.56439L0.874495 6.56439C0.38784 6.57722 0 6.97564 0 7.46272C0 7.94981 0.38784 8.34823 0.874495 8.36106L4.94592 8.35982Z"
                    fill="#f75c06"
                  />
                </svg>
                Map
              </button>
            )}
          </>
        )}
        <Search id="mobile-search" onSubmit={handleSearchSubmit} />

        {!showAllPermits && (
          <button
            type="button"
            className={
              filterOpen
                ? styles.filterBtn + " " + styles.open
                : styles.filterBtn
            }
            onClick={() => {
              dispatch({
                type: "set_filter-tab",
                payload: !filterOpen,
              });

              dispatch({
                type: "set_filter-types-dropdown",
                payload: false,
              });

              const dropdownState = {
                showDateDropdown: false,
                showDateRange: false,
              };

              dispatch({
                type: "set_filter-dates-dropdown",
                payload: dropdownState,
              });
            }}
          >
            <p>Filter</p>
            {!filterOpen ? (
              <svg width="18" height="21" viewBox="0 0 18 21" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.7777 2.66666C16.9581 2.42617 16.9871 2.10442 16.8527 1.83554C16.7182 1.56666 16.4434 1.39682 16.1428 1.39682H1.842C1.54138 1.39682 1.26657 1.56666 1.13213 1.83554C0.997694 2.10442 1.02671 2.42617 1.20707 2.66666L7.4039 10.9929V18.8571C7.4039 19.1578 7.57374 19.4326 7.84262 19.567C8.1115 19.7014 8.43325 19.6724 8.67374 19.4921L10.261 18.3016C10.4609 18.1517 10.5785 17.9165 10.5785 17.6667V10.9921L16.7777 2.66666Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                className={styles.closeButton}
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#2E393E"
                  d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.5 16.084l-1.403 1.416-4.09-4.096-4.102 4.096-1.405-1.405 4.093-4.092-4.093-4.098 1.405-1.405 4.088 4.089 4.091-4.089 1.416 1.403-4.092 4.087 4.092 4.094z"
                />
              </svg>
            )}
          </button>
        )}
      </div>
      <div
        className={
          filterOpen ? styles.filterTab + " " + styles.open : styles.filterTab
        }
      >
        <div className={styles.titleButton}>
          <p>Filters</p>
          {showTypesDropdown || showDateDropdown ? (
            <button
              onClick={() => {
                setDateRangeVal(null);
                setDateRangeTypeVal(null);
                setPreSelectedTypesVal([]);

                const state = {
                  dateRange: null,
                  dateRangeType: null,
                  dateType: null,
                };

                dispatch({
                  type: "set_filter-dates",
                  payload: state,
                });

                dispatch({
                  type: "set_filter-dates-dropdown",
                  payload: {
                    showDateDropdown,
                    showDateRange: false,
                  },
                });
              }}
            >
              Clear Filters
            </button>
          ) : null}
        </div>
        <InputType
          onFilter={() => {
            const newPoints = [
              map.getBounds().getSouthWest().lng,
              map.getBounds().getSouthWest().lat,
              map.getBounds().getNorthEast().lng,
              map.getBounds().getNorthEast().lat,
            ];

            getLocationData("", newPoints);
          }}
          selectedTypes={selectedTypes}
          showTypesDropdown={showTypesDropdown}
          preSelectedTypesVal={preSelectedTypesVal}
          setPreSelectedTypesVal={setPreSelectedTypesVal}
        />
        <InputTrade
          onFilter={() => {
            const newPoints = [
              map.getBounds().getSouthWest().lng,
              map.getBounds().getSouthWest().lat,
              map.getBounds().getNorthEast().lng,
              map.getBounds().getNorthEast().lat,
            ];

            getLocationData("", newPoints);
          }}
          selectedTrades={selectedTrades}
          showTradesDropdown={showTradesDropdown}
          preSelectedTradesVal={preSelectedTradesVal}
          setPreSelectedTradesVal={setPreSelectedTradesVal}
        />
        <InputDate
          showDateRange={showDateRange}
          showDateDropdown={showDateDropdown}
          dateRangeVal={dateRangeVal}
          setDateRangeVal={() => {
            const newPoints = [
              map.getBounds().getSouthWest().lng,
              map.getBounds().getSouthWest().lat,
              map.getBounds().getNorthEast().lng,
              map.getBounds().getNorthEast().lat,
            ];

            getLocationData("", newPoints);
          }}
          dateRangeTypeVal={dateRangeTypeVal}
          setDateRangeTypeVal={() => {
            const newPoints = [
              map.getBounds().getSouthWest().lng,
              map.getBounds().getSouthWest().lat,
              map.getBounds().getNorthEast().lng,
              map.getBounds().getNorthEast().lat,
            ];

            getLocationData("", newPoints);
          }}
        />
      </div>
    </div>
  );
};

export default Navbar;
