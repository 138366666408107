import React from 'react';

const SubLogo = ({ className }) => (
  <svg className={className} width="200" height="29" viewBox="0 0 200 29" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M45.7554 3.11798C62.2648 3.11798 78.0918 4.53313 89.3932 7.00446V22.0377C78.0909 24.5085 62.2627 25.9227 45.7554 25.9227C29.2493 25.9227 13.4223 24.5085 2.12036 22.0377V7.00446C13.4211 4.53313 29.2473 3.11798 45.7554 3.11798M0.828826 5.11441L0.00356612 5.30223L0 6.14829V23.7415L0.834473 23.9293C12.3316 26.5441 28.7031 28.0431 45.7554 28.0431C62.8089 28.0431 79.1834 26.5441 90.6773 23.9293L91.5034 23.7415L91.5126 22.8942V5.30223L90.6859 5.11441C79.1896 2.49866 62.8122 0.998512 45.7554 0.998512C28.699 0.998512 12.3233 2.49866 0.828826 5.11441" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.09314 18.2095V9.00654H5.65332V20.1061H8.09314V18.2095" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.9124 14.0743C13.8758 13.6077 14.4206 12.8461 14.4206 11.7575C14.4206 9.69005 12.99 9.00684 10.8758 9.00684H9.16113V10.9019H10.3784C11.2488 10.9019 12.0574 11.1207 12.0574 12.1777C12.0574 13.1108 11.4191 13.4984 10.5641 13.4984H9.16113V15.162H10.7513C11.7929 15.162 12.5085 15.614 12.5085 16.7483C12.5085 17.8975 11.6684 18.2098 10.705 18.2098H9.16113V20.1064H11.0316C13.0363 20.1064 14.9484 19.1423 14.9484 16.8883C14.9484 15.5046 14.2651 14.4633 12.9124 14.0743" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20.8976 18.2811C19.4037 18.0779 19.147 17.0012 19.147 15.9082V9.00624H16.7051V15.9082C16.7051 18.74 18.2406 20.1866 20.8976 20.3533V18.2811" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M23.732 9.00654V15.9085C23.732 17.3744 23.2881 18.1364 21.9653 18.2877V20.353C24.6126 20.1842 26.1734 18.7239 26.1734 15.9085V9.00654H23.732" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M30.8682 15.7691V9.00654H28.4277V20.1058H30.8682V15.7691" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M37.5226 17.4793C37.445 16.359 37.1029 15.1944 35.9053 14.8826V14.8517C37.1333 14.3542 37.6625 13.3747 37.6625 12.0681C37.6625 10.3896 36.4037 9.00684 34.4129 9.00684H31.9365V10.9019H33.5425C34.6301 10.9019 35.2215 11.3685 35.2215 12.4416C35.2215 13.562 34.6301 14.0276 33.5425 14.0276H31.9365V15.7685H33.3095C34.5374 15.7685 34.9885 16.2817 35.1594 17.4478C35.2839 18.3328 35.253 19.4062 35.5472 20.1064H37.9882C37.5535 19.4841 37.5689 18.178 37.5226 17.4793" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M58.4112 9.00654V13.2654H54.9868V15.3174H58.4112V20.1061H60.8522V9.00654H58.4112" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M53.9178 15.3174V9.00654H51.478V20.1061H53.9178V15.3174" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M64.8551 18.9346L65.3131 17.6342H65.316L65.9621 15.8152H65.9508L67.3963 11.7429H67.4052L68.3749 9.00684H66.1844L61.9707 20.1064H64.4411L64.8551 18.9346" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M69.1083 10.1331L67.9745 13.3328L68.8274 15.8149H67.0949L66.4497 17.6339H69.4643L70.3044 20.1058H72.8384L69.1083 10.1331" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M76.2424 12.318V9.00654H73.9565V20.1058H76.2424V12.318" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M83.6279 20.1061H85.9129V9.00654H83.6279V20.1061Z" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M82.5587 9.00654H82.4764L80.0199 16.6389H79.9902L77.3923 9.00654H77.3105V15.2865L78.9938 20.1061H80.8749L82.5587 15.2371V9.00654" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M49.1767 18.1655V9.00684H46.8911V14.4832L49.1767 18.1655" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M45.4325 14.1489L42.2328 9.00684H39.8516L45.1609 17.5617L46.7357 20.1064H49.1235L45.4325 14.1489" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M39.8066 10.9608V20.1061H42.0931V14.644L39.8066 10.9608" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M102.408 15.2799C102.408 14.9736 102.292 14.7174 102.061 14.5106C101.83 14.3037 101.42 14.1299 100.832 13.989C100.121 13.8193 99.5649 13.5691 99.1664 13.2386C98.7672 12.9082 98.5678 12.4927 98.5678 11.9926C98.5678 11.4675 98.7827 11.0291 99.2124 10.677C99.6421 10.3251 100.199 10.1489 100.883 10.1489C101.619 10.1489 102.205 10.351 102.641 10.7545C103.077 11.1578 103.287 11.6262 103.272 12.1593L103.263 12.1869H102.394C102.394 11.7926 102.264 11.4737 102.004 11.2303C101.743 10.9869 101.37 10.8654 100.883 10.8654C100.43 10.8654 100.083 10.9676 99.8409 11.1727C99.599 11.3774 99.4781 11.6446 99.4781 11.9744C99.4781 12.2669 99.6091 12.5111 99.871 12.7067C100.133 12.9022 100.558 13.074 101.146 13.222C101.855 13.3976 102.394 13.6562 102.764 13.9982C103.133 14.34 103.318 14.7637 103.318 15.2689C103.318 15.8113 103.096 16.247 102.653 16.5765C102.209 16.9064 101.63 17.071 100.915 17.071C100.244 17.071 99.6499 16.8886 99.1337 16.5236C98.6178 16.1584 98.3675 15.671 98.383 15.0609L98.3922 15.0333H99.2609C99.2609 15.4648 99.4196 15.7929 99.7369 16.0175C100.054 16.2425 100.447 16.3548 100.915 16.3548C101.374 16.3548 101.738 16.2585 102.006 16.0654C102.274 15.8725 102.408 15.6107 102.408 15.2799" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M104.547 16.9741H105.547V11.9744H104.547V16.9741ZM104.547 10.6942H105.547V9.76553H104.547V10.6942Z" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M107.67 11.9744L107.735 12.6306C107.898 12.3932 108.106 12.2092 108.357 12.0782C108.608 11.9474 108.9 11.882 109.232 11.882C109.565 11.882 109.851 11.959 110.09 12.1129C110.328 12.2669 110.508 12.4981 110.628 12.8059C110.785 12.5227 110.993 12.2978 111.252 12.1314C111.511 11.9649 111.812 11.882 112.157 11.882C112.665 11.882 113.068 12.0568 113.364 12.4062C113.659 12.756 113.807 13.282 113.807 13.9842V16.9741H112.897V13.975C112.897 13.4823 112.812 13.1334 112.643 12.9284C112.473 12.7236 112.22 12.6211 111.885 12.6211C111.574 12.6211 111.322 12.729 111.129 12.9447C110.937 13.1605 110.824 13.433 110.79 13.7626V16.9741H109.875V13.975C109.875 13.507 109.788 13.164 109.614 12.9468C109.44 12.7299 109.189 12.6211 108.863 12.6211C108.586 12.6211 108.357 12.6782 108.179 12.7923C108 12.9061 107.863 13.0663 107.768 13.2728V16.9741H106.857V11.9744H107.67" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M118.479 14.564C118.479 13.9982 118.367 13.5328 118.144 13.1682C117.921 12.8033 117.592 12.6211 117.157 12.6211C116.896 12.6211 116.672 12.6805 116.487 12.7988C116.302 12.9174 116.153 13.0796 116.039 13.2859V15.6996C116.153 15.9058 116.302 16.0666 116.487 16.1819C116.672 16.2972 116.899 16.3548 117.167 16.3548C117.598 16.3548 117.924 16.1973 118.146 15.8817C118.368 15.5664 118.479 15.1596 118.479 14.6612V14.564ZM119.389 14.6636C119.389 15.3845 119.217 15.9661 118.872 16.408C118.527 16.8502 118.051 17.071 117.444 17.071C117.135 17.071 116.865 17.0196 116.633 16.9162C116.4 16.8131 116.202 16.6582 116.039 16.452V18.8963H115.129V11.9744H115.826L115.97 12.6211C116.133 12.381 116.336 12.1976 116.58 12.0713C116.823 11.945 117.107 11.882 117.43 11.882C118.049 11.882 118.53 12.1275 118.874 12.6187C119.217 13.1103 119.389 13.7596 119.389 14.5667V14.6636V14.6636Z" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M120.582 16.9741H121.492V9.76553H120.582V16.9741Z" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M124.421 15.1209L124.583 15.7495H124.611L125.84 11.9744H126.852L124.75 17.7412C124.623 18.077 124.442 18.3697 124.206 18.619C123.971 18.8686 123.653 18.9935 123.252 18.9935C123.178 18.9935 123.084 18.9848 122.97 18.9679C122.856 18.951 122.769 18.9349 122.707 18.9195L122.799 18.2033C122.781 18.2 122.835 18.2033 122.964 18.2125C123.091 18.2217 123.172 18.2264 123.206 18.2264C123.4 18.2264 123.559 18.14 123.682 17.9676C123.805 17.7949 123.908 17.6039 123.991 17.3946L124.209 16.8722L122.351 11.9744H123.368L124.421 15.1209" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M130.715 14.6636C130.715 15.1658 130.821 15.5694 131.034 15.8743C131.247 16.1792 131.57 16.3319 132.004 16.3319C132.279 16.3319 132.508 16.2695 132.693 16.1447C132.878 16.0199 133.03 15.8437 133.15 15.6155V13.3144C133.034 13.1016 132.881 12.9331 132.693 12.8083C132.505 12.6835 132.279 12.6211 132.014 12.6211C131.579 12.6211 131.254 12.803 131.039 13.1664C130.823 13.5299 130.715 13.9967 130.715 14.5667V14.6636ZM129.805 14.5667C129.805 13.7626 129.98 13.1141 130.332 12.6211C130.683 12.1284 131.168 11.882 131.787 11.882C132.105 11.882 132.383 11.9459 132.624 12.0737C132.864 12.2015 133.064 12.3854 133.224 12.6259L133.335 11.9744H134.061V17.0018C134.061 17.6425 133.874 18.1346 133.502 18.4782C133.129 18.8217 132.591 18.9935 131.889 18.9935C131.648 18.9935 131.389 18.9602 131.11 18.8942C130.831 18.8279 130.586 18.7376 130.373 18.6238L130.604 17.9168C130.768 18.0092 130.965 18.0838 131.196 18.1409C131.427 18.1976 131.655 18.2264 131.88 18.2264C132.323 18.2264 132.646 18.1254 132.848 17.9236C133.049 17.7218 133.15 17.4146 133.15 17.0018V16.438C132.987 16.6475 132.791 16.8053 132.564 16.9117C132.335 17.0181 132.074 17.071 131.778 17.071C131.165 17.071 130.683 16.8493 130.332 16.4056C129.98 15.962 129.805 15.3813 129.805 14.6636V14.5667V14.5667Z" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M137.318 12.5982C137.013 12.5982 136.753 12.7263 136.538 12.9828C136.322 13.2389 136.189 13.5605 136.14 13.9474L136.149 13.9706H138.455V13.8508C138.455 13.4945 138.359 13.1967 138.169 12.9572C137.978 12.718 137.694 12.5982 137.318 12.5982ZM137.457 17.071C136.761 17.071 136.207 16.8395 135.796 16.3756C135.385 15.912 135.179 15.3105 135.179 14.5712V14.3679C135.179 13.6562 135.391 13.0642 135.814 12.5911C136.238 12.1183 136.739 11.882 137.318 11.882C137.993 11.882 138.503 12.085 138.848 12.4918C139.193 12.8984 139.365 13.4407 139.365 14.1183V14.6868H136.122L136.108 14.71C136.117 15.189 136.239 15.5831 136.473 15.8918C136.707 16.2006 137.035 16.3548 137.457 16.3548C137.765 16.3548 138.035 16.3108 138.268 16.2232C138.501 16.1355 138.701 16.0146 138.871 15.8603L139.227 16.4526C139.048 16.6294 138.813 16.7765 138.52 16.8945C138.227 17.0122 137.873 17.071 137.457 17.071V17.071Z" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M141.588 10.7682V11.9744H142.535V12.649H141.588V15.6847C141.588 15.9189 141.637 16.0838 141.734 16.1792C141.831 16.2749 141.959 16.3224 142.12 16.3224C142.172 16.3224 142.23 16.3165 142.293 16.304C142.356 16.2918 142.412 16.2764 142.461 16.258L142.581 16.8817C142.514 16.937 142.414 16.9825 142.284 17.0181C142.153 17.0532 142.021 17.071 141.888 17.071C141.519 17.071 141.225 16.9593 141.006 16.7361C140.787 16.5129 140.678 16.1623 140.678 15.6847V12.649H139.883V11.9744H140.678V10.7682H141.588" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M144.859 10.7682V11.9744H145.807V12.649H144.859V15.6847C144.859 15.9189 144.908 16.0838 145.005 16.1792C145.102 16.2749 145.231 16.3224 145.391 16.3224C145.443 16.3224 145.501 16.3165 145.564 16.304C145.627 16.2918 145.684 16.2764 145.733 16.258L145.853 16.8817C145.785 16.937 145.686 16.9825 145.555 17.0181C145.424 17.0532 145.292 17.071 145.16 17.071C144.79 17.071 144.496 16.9593 144.277 16.7361C144.059 16.5129 143.949 16.1623 143.949 15.6847V12.649H143.154V11.9744H143.949V10.7682H144.859" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M147.003 16.9741H148.003V11.9744H147.003V16.9741ZM147.003 10.6942H148.003V9.76553H147.003V10.6942Z" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M150.127 11.9744L150.192 12.7183C150.358 12.4532 150.567 12.2475 150.818 12.1013C151.069 11.9551 151.355 11.882 151.675 11.882C152.214 11.882 152.632 12.0398 152.927 12.3554C153.223 12.6713 153.371 13.1587 153.371 13.8181V16.9741H152.461V13.8366C152.461 13.3961 152.374 13.0832 152.2 12.8984C152.026 12.7135 151.76 12.6211 151.403 12.6211C151.141 12.6211 150.909 12.6844 150.707 12.8107C150.505 12.937 150.344 13.1094 150.224 13.3281V16.9741H149.314V11.9744H150.127" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M155.405 14.6636C155.405 15.1658 155.511 15.5694 155.724 15.8743C155.936 16.1792 156.26 16.3319 156.694 16.3319C156.968 16.3319 157.198 16.2695 157.382 16.1447C157.567 16.0199 157.72 15.8437 157.84 15.6155V13.3144C157.723 13.1016 157.57 12.9331 157.382 12.8083C157.194 12.6835 156.968 12.6211 156.703 12.6211C156.269 12.6211 155.943 12.803 155.728 13.1664C155.512 13.5299 155.405 13.9967 155.405 14.5667V14.6636ZM154.494 14.5667C154.494 13.7626 154.67 13.1141 155.021 12.6211C155.372 12.1284 155.857 11.882 156.477 11.882C156.794 11.882 157.073 11.9459 157.313 12.0737C157.553 12.2015 157.754 12.3854 157.914 12.6259L158.025 11.9744H158.75V17.0018C158.75 17.6425 158.564 18.1346 158.191 18.4782C157.818 18.8217 157.28 18.9935 156.578 18.9935C156.338 18.9935 156.078 18.9602 155.8 18.8942C155.521 18.8279 155.275 18.7376 155.063 18.6238L155.294 17.9168C155.457 18.0092 155.654 18.0838 155.885 18.1409C156.116 18.1976 156.344 18.2264 156.569 18.2264C157.013 18.2264 157.335 18.1254 157.537 17.9236C157.739 17.7218 157.84 17.4146 157.84 17.0018V16.438C157.676 16.6475 157.481 16.8053 157.253 16.9117C157.025 17.0181 156.763 17.071 156.467 17.071C155.855 17.071 155.372 16.8493 155.021 16.4056C154.67 15.962 154.494 15.3813 154.494 14.6636V14.5667V14.5667Z" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M162.498 16.9741H163.498V11.9744H162.498V16.9741ZM162.498 10.6942H163.498V9.76553H162.498V10.6942Z" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M166.009 10.7682V11.9744H166.957V12.649H166.009V15.6847C166.009 15.9189 166.058 16.0838 166.155 16.1792C166.252 16.2749 166.381 16.3224 166.541 16.3224C166.593 16.3224 166.651 16.3165 166.714 16.304C166.777 16.2918 166.833 16.2764 166.883 16.258L167.003 16.8817C166.935 16.937 166.836 16.9825 166.705 17.0181C166.574 17.0532 166.442 17.071 166.31 17.071C165.94 17.071 165.646 16.9593 165.427 16.7361C165.209 16.5129 165.099 16.1623 165.099 15.6847V12.649H164.304V11.9744H165.099V10.7682H166.009" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M171.134 14.6636C171.134 15.1691 171.237 15.5733 171.444 15.8767C171.65 16.1801 171.97 16.3319 172.405 16.3319C172.676 16.3319 172.904 16.2701 173.089 16.1471C173.274 16.0238 173.425 15.8511 173.542 15.6294V13.2957C173.425 13.0894 173.273 12.9254 173.086 12.8039C172.9 12.682 172.676 12.6211 172.414 12.6211C171.977 12.6211 171.654 12.8015 171.446 13.162C171.238 13.5224 171.134 13.9905 171.134 14.5667V14.6636ZM170.224 14.5667C170.224 13.7626 170.395 13.1141 170.739 12.6211C171.082 12.1284 171.564 11.882 172.183 11.882C172.476 11.882 172.735 11.9358 172.962 12.0437C173.188 12.1516 173.381 12.3088 173.542 12.515V9.76553H174.452V16.9741H173.708L173.602 16.3596C173.438 16.5935 173.238 16.7709 173.001 16.8909C172.764 17.011 172.488 17.071 172.174 17.071C171.564 17.071 171.086 16.8502 170.741 16.408C170.396 15.9661 170.224 15.3845 170.224 14.6636V14.5667V14.5667Z" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M183.679 11.9744L183.744 12.7183C183.91 12.4532 184.119 12.2475 184.371 12.1013C184.621 11.9551 184.907 11.882 185.227 11.882C185.767 11.882 186.184 12.0398 186.48 12.3554C186.775 12.6713 186.923 13.1587 186.923 13.8181V16.9741H186.013V13.8366C186.013 13.3961 185.926 13.0832 185.752 12.8984C185.578 12.7135 185.312 12.6211 184.955 12.6211C184.693 12.6211 184.461 12.6844 184.26 12.8107C184.058 12.937 183.897 13.1094 183.777 13.3281V16.9741H182.866V11.9744H183.679" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M190.181 12.5982C189.876 12.5982 189.616 12.7263 189.4 12.9828C189.184 13.2389 189.052 13.5605 189.003 13.9474L189.012 13.9706H191.318V13.8508C191.318 13.4945 191.222 13.1967 191.031 12.9572C190.84 12.718 190.557 12.5982 190.181 12.5982ZM190.32 17.071C189.623 17.071 189.07 16.8395 188.658 16.3756C188.247 15.912 188.042 15.3105 188.042 14.5712V14.3679C188.042 13.6562 188.253 13.0642 188.677 12.5911C189.101 12.1183 189.602 11.882 190.181 11.882C190.856 11.882 191.366 12.085 191.711 12.4918C192.055 12.8984 192.228 13.4407 192.228 14.1183V14.6868H188.984L188.97 14.71C188.98 15.189 189.101 15.5831 189.336 15.8918C189.57 16.2006 189.898 16.3548 190.32 16.3548C190.628 16.3548 190.898 16.3108 191.131 16.2232C191.363 16.1355 191.564 16.0146 191.734 15.8603L192.089 16.4526C191.911 16.6294 191.675 16.7765 191.382 16.8945C191.09 17.0122 190.736 17.071 190.32 17.071V17.071Z" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M193.333 16.9741H194.243V16.0315H193.333V16.9741Z" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M196.043 10.6817H194.963V13.6853H194.508V10.6817H193.43V10.3236H196.043V10.6817" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M197.119 10.3236L198.19 13.055H198.204L199.276 10.3236H199.858V13.6853H199.403V12.3554L199.449 10.9887L199.437 10.9863L198.348 13.6853H198.045L196.96 10.9935L196.949 10.9955L196.992 12.3554V13.6853H196.538V10.3236H197.119" fill="#FFFFFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M181.35 14.6559C181.35 16.1816 180.113 17.4181 178.587 17.4181C177.062 17.4181 175.825 16.1816 175.825 14.6559C175.825 13.1305 177.062 11.8939 178.587 11.8939C180.113 11.8939 181.35 13.1305 181.35 14.6559" fill="#EB8F2D" />
  </svg>
);

export default SubLogo;
