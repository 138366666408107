import React, { createContext, useReducer } from "react";
import Reducer from "./reducer";

const initialState = {
  map: null,
  bound: null,
  places: null,
  loading: false,
  data: null,
  activePopup: null,
  activePermit: null,
  contractors: null,
  sideBarOpen: true,
  modalOpen: false,
  widgetOpen: true,
  filterOpen: false,
  dateType: null,
  showTypesDropdown: false,
  showTradesDropdown: false,
  showDateDropdown: false,
  showDateRange: false,
  selectedTypes: [],
  selectedTrades: [],
  menuOverlay: false,
  permitMobilePanel: false,
  showAllPermits: false,
  showPermitDetails: false,
  loaderText: "Loading Location",
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;
