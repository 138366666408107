import React, { useContext, useRef, useEffect } from "react";
import { snowflakeFetch } from "../../core/service/api";
import classnames from "classnames";

import styles from "./side-widget.scss";

import { Context } from "../../core/store";
import PermitPanel from "./permit-panel";
import AboutPanel from "./about-panel";

const SideWidget = () => {
  const [state, dispatch] = useContext(Context);
  const { activePermit, widgetOpen, contractors } = state;
  const sideWidget = useRef(null);

  useEffect(() => {
    if (activePermit) {
      dispatch({
        type: "set_contractors",
        payload: null,
      });
      snowflakeFetch(
        "permit-contractors",
        { permitId: activePermit.PERMITID },
        ({ data }) => {
          dispatch({
            type: "set_contractors",
            payload: data,
          });
        },
        false
      );
    }
  }, [activePermit]);

  return (
    <>
      <div
        className={classnames(styles.overlay, {
          [styles.close]: !widgetOpen,
        })}
        onClick={() => {
          dispatch({
            type: "set_side-widget",
            payload: false,
          });
        }}
      ></div>
      <aside
        className={classnames(styles.sideWidget, {
          [styles.close]: !widgetOpen,
        })}
        ref={sideWidget}
      >
        {activePermit ? (
          <PermitPanel
            styles={styles}
            activePermit={activePermit}
            contractors={contractors ? contractors.data : null}
          />
        ) : (
          <AboutPanel styles={styles} dispatch={dispatch} />
        )}
        <button
          type="button"
          className={
            widgetOpen ? styles.toggleBtn + " " + styles.open : styles.toggleBtn
          }
          onClick={() => {
            dispatch({
              type: "set_side-widget",
              payload: !widgetOpen,
            });
          }}
        >
          <span className={styles.open}>»</span>
          <span className={styles.close}>«</span>
        </button>
        <footer className={styles.footer}>
          <ul className={styles.footerLinks}>
            <li>
              <a href="/">Terms of Service</a>
            </li>
            <li>
              <a href="/">Privacy Policy</a>
            </li>
          </ul>
          <div>&copy; 2020 Burnham Nationwide, Inc.</div>
        </footer>
      </aside>
    </>
  );
};

export default SideWidget;
