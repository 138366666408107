import React from 'react';

const MenuOverlay = ({
  styles,
}) => (
  <div className={styles.menuOverlay}>
    <ul className={styles.menuList}>
      <li>
        <a href="/">About Burnham</a>
      </li>
      <li>
        <a href="/">Expedite Your Permit</a>
      </li>
      <li>
        <a href="/">Frequently Asked Questions</a>
      </li>
      <li>
        <a href="/">Visit Burnham Nationwide</a>
      </li>
    </ul>
    <div className={styles.menuBottom}>
      <div className={styles.shareBox}>
        <h3>Share this</h3>
        <ul>
          <li>
            <a href="/" title="Share on Facebook">
              <svg width="14" height="27" viewBox="0 0 14 27" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.7466 5.0024C12.7466 5.0024 10.6556 4.66491 10.3097 4.66491C9.7904 4.66491 8.55015 4.86839 8.55015 6.08928C8.55015 7.30979 8.55015 9.27703 8.55015 9.27703H12.8817L12.4759 13.2115L8.55015 13.2795V26.1656H3.27102V13.2795L0.631836 13.2115L0.698651 9.20907C0.698651 9.20907 1.51144 9.20907 3.33897 9.20907C3.33897 7.62678 2.86937 3.16309 5.00859 1.6123C8.03082 -0.581196 13.4227 0.933906 13.4227 0.933906L12.7466 5.0024" fill="#ececeb" />
              </svg>
            </a>
          </li>
          <li>
            <a href="/" title="Share on Twitter">
              <svg width="26" height="22" viewBox="0 0 26 22" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.00195312 19.2786C1.57628 20.4774 2.85145 21.0397 4.87488 21.4531C6.90058 21.8657 9.82411 22.278 14.2487 19.878C18.6706 17.4787 20.3968 15.2306 21.8212 11.7797C23.2448 8.33194 22.7197 5.33249 22.7197 5.33249C22.7197 5.33249 23.6199 4.88301 24.1441 4.43353C24.6691 3.98404 25.119 3.23352 25.119 3.23352C25.119 3.23352 24.1441 3.45826 23.5458 3.5338C22.9452 3.60897 22.4202 3.45826 22.4202 3.45826C22.4202 3.45826 23.17 3.0084 23.3951 2.78366C23.6199 2.55853 24.0709 2.10905 24.2945 1.65881C24.5192 1.20857 24.5192 0.833498 24.5192 0.833498C24.5192 0.833498 23.4703 1.35853 22.7197 1.58327C21.9707 1.80877 21.2961 1.80877 21.2961 1.80877C21.2961 1.80877 19.6463 -0.590493 16.1214 0.384396C11.9694 1.53278 12.2978 6.75724 12.2978 6.75724C12.2978 6.75724 8.54969 6.5325 5.92569 5.10775C3.62704 3.85877 1.27675 1.28412 1.27675 1.28412C1.27675 1.28412 0.377407 2.48413 0.676935 4.80784C0.977222 7.13155 2.927 8.25716 2.927 8.25716C2.927 8.25716 2.10055 8.40673 1.57628 8.25716C1.05201 8.1072 0.526981 7.80691 0.526981 7.80691C0.526981 7.80691 0.227453 9.53157 1.72585 11.0307C3.22577 12.531 4.80237 13.0557 4.80237 13.0557L2.47714 13.28C2.47714 13.28 3.00141 14.7803 4.50056 15.6793C6.00124 16.5779 7.20011 16.8041 7.20011 16.8041C7.20011 16.8041 5.29019 18.1233 3.8252 18.6791C2.64987 19.1256 0.00195312 19.2786 0.00195312 19.2786" fill="#ececeb" />
              </svg>
            </a>
          </li>
          <li>
            <a href="/" title="Share on LinkedIn">
              <svg width="24" height="23" viewBox="0 0 24 23" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M17.9705 7.37916C14.6138 7.37916 13.7721 9.36272 13.2828 10.0658V7.71399H8.32825V22.6452H13.2828V13.1871C13.454 12.8872 13.5489 12.5379 13.8515 12.2005C14.4551 11.5308 15.1578 11.1629 16.0283 11.1629C17.065 11.1629 17.6679 11.6656 18.0703 12.3007C18.4708 12.9373 18.5399 13.3059 18.5399 13.9073V22.6452H23.3616V13.9073C23.3616 10.393 21.6525 7.37916 17.9705 7.37916ZM0.561407 22.6452H5.64996V7.71399H0.561407V22.6452ZM5.85117 3.16109C5.85117 4.60331 4.61433 5.77219 3.08822 5.77219C1.56401 5.77219 0.326416 4.60331 0.326416 3.16109C0.326416 1.71812 1.56401 0.549622 3.08822 0.549622C4.61433 0.549622 5.85117 1.71812 5.85117 3.16109V3.16109Z" fill="#ececeb" />
              </svg>
            </a>
          </li>
          <li>
            <a href="/" title="Send to Mail">
              <svg width="28" height="19" viewBox="0 0 28 19" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M1.8125 1.54169H26.1875V17.7917H1.8125V1.54169Z" stroke="#ececeb" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17.9941 7.4729L22.1249 11.2917" stroke="#ececeb" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10.0058 7.4729L5.875 11.2917" stroke="#ececeb" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M25.7867 2.09851L15.481 9.20518C14.5896 9.8206 13.4105 9.8206 12.5191 9.20518L2.21338 2.09851" stroke="#ececeb" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </li>
        </ul>
      </div>
      <footer className={styles.footer}>
        <ul className={styles.footerLinks}>
          <li>
            <a href="/">Terms of Service</a>
          </li>
          <li>
            <a href="/">Privacy Policy</a>
          </li>
        </ul>
        <div>&copy; 2020 Burnham Nationwide, Inc.</div>
      </footer>
    </div>
  </div>
);

export default MenuOverlay;
