import React, { useContext, useRef, useEffect, useState, useMemo } from "react";
import mapboxgl from "mapbox-gl";
import PermitMobilePanel from "./permit-mobile-panel";

import { snowflakeFetch } from "../../core/service/api";
import { Context } from "../../core/store";
import {
  createDataSource,
  destroyDataSource,
  createMarkerLayers,
  destroyMarkerLayers,
  createHeatMapLayers,
  destroyHeatMapLayers,
} from "./map-functions";

import NoDataOverlay from "./no-data-overlay";
import { SearchIcon } from "../search/search";

import styles from "./map.scss";

mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN;

const DEFAULT_CENTER = [-74.1314837, 40.7185042];

const Map = () => {
  const [isHeatMapShown, setIsHeatMapShown] = useState(false);
  const [state, dispatch] = useContext(Context);
  const {
    data,
    dateType,
    dateRange,
    map: mapBox,
    loading,
    loaderText,
    permitMobilePanel,
    selectedTrades,
    selectedTypes,
  } = state;

  const mapContainerRef = useRef(null);

  const searchLocation = (map, silentLoad = false) => {
    if (!silentLoad) {
      dispatch({
        type: "set_loader-text",
        payload: "Loading Permits",
      });

      dispatch({
        type: "set_loading",
        payload: true,
      });
    }

    const points = [
      map.getBounds().getSouthWest().lng,
      map.getBounds().getSouthWest().lat,
      map.getBounds().getNorthEast().lng,
      map.getBounds().getNorthEast().lat,
    ];

    const params = {
      bounds: points.join(","),
      type:
        selectedTypes.length > 0
          ? selectedTypes.map((type) => type.label).join(",")
          : null,
      trade:
        selectedTrades.length > 0
          ? selectedTrades.map((trade) => trade.label).join(",")
          : null,
      dateType: dateType ? dateType.value : null,
      dateRange: dateRange
        ? `${dayjs(dateRange.from).format("YYYY-MM-DD")},${dayjs(
            dateRange.to
          ).format("YYYY-MM-DD")}`
        : null,
    };

    snowflakeFetch("permits", params, (res) => {
      if (res.statusText === "OK") {
        dispatch({
          type: "set_data",
          payload: {
            ...res.data,
            searchKey: "",
          },
        });

        dispatch({
          type: "set_loading",
          payload: false,
        });
      }
    });
  };

  const initializeMap = async () => {
    // Add mapbox styles to the page.
    const url = "https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css";
    if (!document.querySelector(`link[href="${url}"]`)) {
      const l = document.createElement("link");
      l.href = url;
      l.rel = "stylesheet";

      document.head.appendChild(l);
    }

    const getMapInstance = (center, zoom = 11) => {
      const map = new mapboxgl.Map({
        center,
        container: mapContainerRef.current,
        pitchWithRotate: false,
        style: "mapbox://styles/nprivaldosjr/ckh5ljwos0dfo19sqyieetdcu",
        zoom,
      });

      // Add Map controls.
      const geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      });
      map.addControl(geolocate, "top-right");
      map.addControl(new mapboxgl.NavigationControl(), "top-right");

      searchLocation(map, true);

      dispatch({
        type: "set_map",
        payload: map,
      });

      return map;
    };

    /**
     *
     * Attempt to get the user's lat/long.
     * if not present set initial center to DEFAULT_CENTER and zoom of 11.
     *
     */
    const callback = (res) => {
      if (res.coords) {
        const lat = res.coords.latitude;
        const long = res.coords.longitude;
        const center = [long, lat];

        getMapInstance(center, 18);
      } else {
        getMapInstance(DEFAULT_CENTER, 11);
      }
    };

    var options = {
      enableHighAccuracy: true,
      timeout: 5000, // 5 seconds
      maximumAge: 0,
    };

    navigator.geolocation.getCurrentPosition(callback, callback, options);
  };

  const handleSearchAreaClick = () => {
    dispatch({
      type: "set_loading",
      payload: true,
    });

    dispatch({
      type: "set_loader-text",
      payload: "Loading Permits",
    });

    const givenPoints = [
      mapBox.getBounds().getSouthWest().lng,
      mapBox.getBounds().getSouthWest().lat,
      mapBox.getBounds().getNorthEast().lng,
      mapBox.getBounds().getNorthEast().lat,
    ];

    const params = {
      bounds: givenPoints.join(","),
      type:
        selectedTypes.length > 0
          ? selectedTypes.map((type) => type.label).join(",")
          : null,
      trade:
        selectedTrades.length > 0
          ? selectedTrades.map((trade) => trade.label).join(",")
          : null,
      dateType: dateType ? dateType.value : null,
      dateRange: dateRange
        ? `${dayjs(dateRange.from).format("YYYY-MM-DD")},${dayjs(
            dateRange.to
          ).format("YYYY-MM-DD")}`
        : null,
    };

    snowflakeFetch("permits", params, (res) => {
      if (res.statusText === "OK") {
        const { data } = res;

        dispatch({
          type: "set_data",
          payload: {
            ...data,
          },
        });
      }

      dispatch({
        type: "set_loading",
        payload: false,
      });
    });
  };

  // component did mount
  useEffect(() => {
    if (!mapBox) {
      initializeMap();
    }
  }, [initializeMap, mapBox]);

  // generate or destroy map data when global data is present or not
  useEffect(() => {
    setIsHeatMapShown(false);

    if (data && mapBox) {
      destroyHeatMapLayers(mapBox);
      destroyMarkerLayers(mapBox);
      destroyDataSource(mapBox);

      createDataSource(
        data,
        {
          cluster: true,
          clusterMaxZoom: 20,
          clusterRadius: 100,
        },
        mapBox
      );

      createMarkerLayers(mapBox, dispatch, state, mapboxgl.Popup);
    } else if (!data && mapBox) {
      destroyHeatMapLayers(mapBox);
      destroyMarkerLayers(mapBox);
      destroyDataSource(mapBox);
    }
  }, [data]);

  // show or hide heatmap
  useEffect(() => {
    if (mapBox) {
      if (isHeatMapShown) {
        destroyMarkerLayers(mapBox);
        destroyDataSource(mapBox);

        createDataSource(data, null, mapBox);
        createHeatMapLayers(mapBox);
      } else {
        destroyMarkerLayers(mapBox);
        destroyHeatMapLayers(mapBox);
        destroyDataSource(mapBox);

        createDataSource(
          data,
          {
            cluster: true,
            clusterMaxZoom: 20,
            clusterRadius: 100,
          },
          mapBox
        );
        createMarkerLayers(mapBox, dispatch, state, mapboxgl.Popup);
      }
    }
  }, [isHeatMapShown]);

  return (
    <div className={styles.mapWrapper}>
      <div className={styles.map} ref={mapContainerRef} />
      <div className={styles.customActions}>
        <button
          className={styles.customActions__btn}
          onClick={handleSearchAreaClick}
        >
          <SearchIcon />
          Search Area
        </button>
        {data && data.totalPermits > 0 && (
          <button
            type="button"
            className={styles.customActions__btn}
            onClick={() => {
              setIsHeatMapShown(!isHeatMapShown);
            }}
          >
            {isHeatMapShown ? (
              <svg width="19" height="22" viewBox="0 0 19 22" fill="none">
                <path
                  d="M5 20.1219C6.07459 20.7008 7.27044 21.0022 8.48404 21C12.6047 20.9422 15.9306 17.5255 15.9839 13.2955C16.0486 11.8522 15.9181 10.4066 15.5961 9"
                  stroke="black"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13 4.55054C11.5743 2.9828 9.77539 1.77256 7.75784 1.02383C7.62084 0.970385 7.46373 1.00887 7.36973 1.11889C7.27573 1.22892 7.26657 1.38507 7.3471 1.50463C8.87555 3.96929 8.91998 7.03473 7.46355 9.53966C5.93703 9.85148 4.52426 8.34116 4.38524 7.16356C2.37576 7.98056 1 10.3741 1 13.1021C0.999674 13.7434 1.08895 14.3817 1.2654 15"
                  stroke="black"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 21L18 4"
                  stroke="black"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg width="17" height="21" viewBox="0 0 17 21" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.49752 1.12254C9.13931 0.927023 8.69507 0.968275 8.38076 1.22624C8.06645 1.4842 7.94727 1.90536 8.08115 2.28498C8.82103 4.48558 8.59414 6.88832 7.45473 8.91869C5.9458 9.03263 4.60483 7.98184 4.38726 6.515C2.16515 7.70467 0.849208 10.0485 1.01383 12.5236C1.01383 16.5054 4.30964 19.7333 8.37524 19.7333C12.4177 19.6791 15.6813 16.4827 15.7367 12.5236C16.1869 7.84023 13.7288 3.3484 9.49752 1.12254Z"
                  fill="#FF7070"
                  stroke="black"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            {isHeatMapShown ? "Hide" : "View"} Heatmap
          </button>
        )}
      </div>
      {data && data.totalPermits > 0 && (
        <button
          type="button"
          className={styles.heatmapBtn}
          onClick={() => {
            setIsHeatMapShown(!isHeatMapShown);
          }}
        >
          {isHeatMapShown ? (
            <svg width="19" height="22" viewBox="0 0 19 22" fill="none">
              <path
                d="M5 20.1219C6.07459 20.7008 7.27044 21.0022 8.48404 21C12.6047 20.9422 15.9306 17.5255 15.9839 13.2955C16.0486 11.8522 15.9181 10.4066 15.5961 9"
                stroke="black"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13 4.55054C11.5743 2.9828 9.77539 1.77256 7.75784 1.02383C7.62084 0.970385 7.46373 1.00887 7.36973 1.11889C7.27573 1.22892 7.26657 1.38507 7.3471 1.50463C8.87555 3.96929 8.91998 7.03473 7.46355 9.53966C5.93703 9.85148 4.52426 8.34116 4.38524 7.16356C2.37576 7.98056 1 10.3741 1 13.1021C0.999674 13.7434 1.08895 14.3817 1.2654 15"
                stroke="black"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 21L18 4"
                stroke="black"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg width="17" height="21" viewBox="0 0 17 21" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.49752 1.12254C9.13931 0.927023 8.69507 0.968275 8.38076 1.22624C8.06645 1.4842 7.94727 1.90536 8.08115 2.28498C8.82103 4.48558 8.59414 6.88832 7.45473 8.91869C5.9458 9.03263 4.60483 7.98184 4.38726 6.515C2.16515 7.70467 0.849208 10.0485 1.01383 12.5236C1.01383 16.5054 4.30964 19.7333 8.37524 19.7333C12.4177 19.6791 15.6813 16.4827 15.7367 12.5236C16.1869 7.84023 13.7288 3.3484 9.49752 1.12254Z"
                fill="#FF7070"
                stroke="black"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          {isHeatMapShown ? "Hide" : "View"} Heatmap
        </button>
      )}
      {loading && (
        <div className={styles.loader}>
          <div className={styles.ring}>
            <div />
            <div />
            <div />
            <div />
          </div>
          <p className={styles.text}>{loaderText}</p>
        </div>
      )}
      {permitMobilePanel && <PermitMobilePanel styles={styles} />}
      {data && !loading && !data.features.length && <NoDataOverlay />}
    </div>
  );
};

export default Map;
