import React, { useEffect, useRef, useState, useContext } from "react";
import { snowflakeFetch } from "../../core/service/api";
import { Context } from "../../core/store";

import styles from "./input-type.scss";

let eventAttached = false;

const InputType = ({
  onFilter,
  selectedTypes,
  showTypesDropdown,
  preSelectedTypesVal,
  setPreSelectedTypesVal,
}) => {
  const [, dispatch] = useContext(Context);
  const [input, setInput] = useState("");
  const [loadingOptions, setLoadingOptions] = useState(true);
  const [options, setOptions] = useState([]);

  const permitTypeRef = useRef(null);
  const dropdownRef = useRef(null);
  const toggleRef = useRef(null);

  const triggerFilter = () => {
    const state = {
      preSelectedTypes: preSelectedTypesVal,
      selectedTypes: preSelectedTypesVal,
    };

    dispatch({
      type: "set_filter-types",
      payload: state,
    });

    dispatch({
      type: "set_filter-types-dropdown",
      payload: false,
    });

    onFilter();
  };

  const displayOptions = () => {
    const filteredOptions = options.filter(
      (x) =>
        input.trim().length === 0 ||
        (x.label && x.label.toLowerCase().includes(input.toLocaleLowerCase()))
    );

    if (filteredOptions.length === 0) {
      return <p className={styles.empty}>Permit type not found</p>;
    }

    return (
      <>
        <ul id="p_type-drop" role="listbox" aria-label="Filter Permit types">
          {filteredOptions.map((option, index) => (
            <li key={`permit-type-${index}`}>
              <label htmlFor={`permit-type-${index}`}>
                <input
                  type="checkbox"
                  name="permit-type"
                  ref={permitTypeRef}
                  id={`permit-type-${index}`}
                  checked={
                    preSelectedTypesVal.findIndex(
                      (x) => x.value === option.value
                    ) > -1
                  }
                  onChange={(e) => {
                    const _index = preSelectedTypesVal.findIndex(
                      (x) => x.value === e.target.value
                    );
                    if (e.target.checked && _index === -1) {
                      setPreSelectedTypesVal([...preSelectedTypesVal, option]);
                    } else if (!e.target.checked && _index > -1) {
                      setPreSelectedTypesVal(
                        preSelectedTypesVal.filter(
                          (x) => x.value !== e.target.value
                        )
                      );
                    }
                  }}
                  value={option.value}
                />
                <span>{option.label}</span>
              </label>
            </li>
          ))}
        </ul>
        <div className={styles.trigger}>
          <button
            type="button"
            className={styles.typeFilterBtn}
            onClick={triggerFilter}
          >
            Apply
          </button>
        </div>
      </>
    );
  };

  const handleClick = (e) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target) &&
      !permitTypeRef.current.contains(e.target) &&
      !toggleRef.current.contains(e.target)
    ) {
      setPreSelectedTypesVal([]);
      dispatch({
        type: "set_filter-types-dropdown",
        payload: false,
      });
    }
  };

  const handleDesktopEvents = () => {
    if (window.innerWidth > 800 && !eventAttached) {
      document.addEventListener("mousedown", handleClick, false);
      eventAttached = true;

      return () => {
        document.removeEventListener("mousedown", handleClick, false);
      };
    } else {
      document.removeEventListener("mousedown", handleClick, false);
    }
  };

  useEffect(() => {
    snowflakeFetch(
      "permit-types",
      null,
      ({ data }) => {
        const newOptions = data.data.map((item) => ({
          value: item.PERMITTYPE || "undefined",
          label: item.PERMITTYPE || "No Permit type",
        }));

        setOptions(newOptions);
        setLoadingOptions(false);
      },
      false
    );

    handleDesktopEvents();
  }, []);

  window.addEventListener("resize", handleDesktopEvents);

  return (
    <div className={styles.inputType}>
      <button
        type="button"
        ref={toggleRef}
        className={styles.inputTypeBtn}
        aria-haspopup="listbox"
        aria-label="Filter Permit types"
        id="p_type-drop-trigger"
        onClick={() => {
          dispatch({
            type: "set_filter-types-dropdown",
            payload: !showTypesDropdown,
          });
        }}
      >
        <div>
          {selectedTypes.length === 0
            ? "Permit Type"
            : `${selectedTypes.length} permit type${
                selectedTypes.length !== 1 ? "s" : ""
              } selected`}
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path d="M12 21l-12-18h24z" />
          </svg>
        </div>
      </button>
      {showTypesDropdown ? (
        <div className={styles.inputTypeDropdown} ref={dropdownRef}>
          {loadingOptions ? (
            <p className={styles.loadingText}>Loading Permit types...</p>
          ) : (
            displayOptions()
          )}
        </div>
      ) : null}
    </div>
  );
};

export default InputType;
