import React from "react";
import ContractorDetails from "./contractor-details";

const PermitPanel = ({ styles, activePermit, contractors }) => (
  <div className={styles.permitWidget}>
    <h2 className={styles.header}>
      Permit No. {activePermit.PERMITNUMBER || "-"}
    </h2>
    <div className={styles.body}>
      <div className={styles.row}>
        <div className={styles.col}>Permit ID</div>
        <div className={styles.col}>
          <strong>{activePermit.PERMITID || "-"}</strong>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>Permit No.</div>
        <div className={styles.col}>
          <strong>{activePermit.PERMITNUMBER || "-"}</strong>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>Data Source</div>
        <div className={styles.col}>
          {(activePermit.DATASOURCE || "-").toUpperCase()}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>Permit Class</div>
        <div className={styles.col}>{activePermit.PERMITCLASS || "-"}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>Permit Type</div>
        <div className={styles.col}>{activePermit.PERMITTYPE || "-"}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>Permit Subtype</div>
        <div className={styles.col}>{activePermit.PERMITSUBTYPE || "-"}</div>
      </div>
      <div className={`${styles.row} ${styles.rowFull}`}>
        <div className={styles.col}>Description</div>
        <div className={styles.col}>
          <p>{activePermit.PERMITDESCRIPTION || "No description yet"}</p>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>Estimated Cost</div>
        <div className={styles.col}>{activePermit.ESTCOST || "-"}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>Lot Area (ft)</div>
        <div className={styles.col}>{activePermit.LOTSQFT || "-"}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>Bldg. Area (ft)</div>
        <div className={styles.col}>{activePermit.BLDGSQFT || "-"}</div>
      </div>
      <div className={`${styles.row} ${styles.rowFull}`}>
        <div className={styles.col}>Address</div>
        <div className={styles.col}>
          <p>
            <strong>
              {activePermit.ADDR1 ||
              activePermit.CITY ||
              activePermit.STATEABBR ||
              activePermit.ZIPCODE
                ? (activePermit.ADDR1 || "") +
                  " " +
                  (activePermit.CITY || "") +
                  " " +
                  (activePermit.STATEABBR || "") +
                  " " +
                  (activePermit.ZIPCODE || "")
                : "No address stated"}
            </strong>
          </p>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>Issue Date</div>
        <div className={styles.col}>
          {activePermit.ISSUEDATE ? activePermit.ISSUEDATE : "-"}
        </div>
      </div>
      <div className={styles.lineSeparator} />
      <div className={`${styles.row} ${styles.rowFull}`}>
        <div className={styles.col}>Contractor/s Company Name & Details</div>
        {contractors &&
          contractors.map((contractor, index) => (
            <ContractorDetails
              key={index}
              styles={styles}
              contractor={contractor}
            />
          ))}
      </div>
    </div>
  </div>
);

export default PermitPanel;
