import React, { useContext, useRef, useEffect } from 'react';
import { Context } from "../../core/store";
import dayjs from 'dayjs';
import PermitPanel from '../side-widget/permit-panel';

const PermitMobilePanel = ({
  styles,
}) => {
  const [state, dispatch] = useContext(Context);
  const {
    address,
    permits,
    showAllPermits,
    showPermitDetails,
    activePermit,
    permitMobilePanel,
    contractors
  } = state;

  const panelRef = useRef(null);

  const handleBlur = (e) => {
    if (panelRef.current && !panelRef.current.contains(e.target)) {
      dispatch({
        type: 'set_permit-panel',
        payload: !permitMobilePanel,
      });
    }
  };

  const handleMobileEvents = () => {
    if (window.innerWidth < 801) {
      document.addEventListener('mousedown', handleBlur, false);

      return () => {
        document.removeEventListener('mousedown', handleBlur, false);
      };
    }
  }

  useEffect(() =>  {
    handleMobileEvents();
  }, [permitMobilePanel]);


  return (
    <div
      className={showAllPermits || showPermitDetails ? styles.permitMobilePanel + ' ' + styles.open : styles.permitMobilePanel}
      ref={panelRef}
    >
      {showPermitDetails && (
        <>
          <PermitPanel
            styles={styles}
            activePermit={activePermit}
            contractors={contractors ? contractors.data : null}
          />
          <div className={styles.meta}>
            <button
              type="button"
              className={styles.metaBtn}
              onClick={() => {
                dispatch({
                  type: 'set_modal',
                  payload: true,
                });
              }}
            >
              Expedite Your Permit Process
              <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
                <circle cx="17" cy="17" r="17" fill="white" />
                <path d="M20.6912 6.76141C24.6269 7.74436 27.5597 10.9538 28.1089 14.8786C28.658 18.8034 26.7141 22.6615 23.1923 24.6364V29.1864" stroke="#F75C06" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path fillRule="evenodd" clipRule="evenodd" d="M14.0244 16.1875C14.0244 18.4312 15.8899 20.25 18.1911 20.25C20.4923 20.25 22.3577 18.4312 22.3577 16.1875C22.3577 13.9438 20.4923 12.125 18.1911 12.125C15.8899 12.125 14.0244 13.9438 14.0244 16.1875Z" stroke="#F75C06" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.6912 12.9375V5.625C15.6912 5.17627 16.0643 4.8125 16.5245 4.8125H19.8578C20.3181 4.8125 20.6912 5.17627 20.6912 5.625V12.9375" stroke="#F75C06" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.19216 21.0744L7.15216 20.8209C6.62649 20.6926 6.1976 20.3232 6.00224 19.8304C5.80687 19.3376 5.86942 18.783 6.16994 18.3433L8.19216 15.3869V14.5744C8.19216 10.7372 11.3788 7.52083 15.691 6.66608" stroke="#F75C06" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.1923 29.1875V26.7619L12.0734 26.9428C11.6136 27.0189 11.1426 26.9993 10.6912 26.8854" stroke="#F75C06" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18.1912 16.1875C18.1912 20.6748 14.4602 24.3125 9.85783 24.3125H8.19116" stroke="#F75C06" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
        </>
      )}
      {showAllPermits && (
        <>
          <div className={styles.container}>
            <div className={styles.address}>
              {
                showPermitDetails ? (
                  <p>permit number</p>
                ) : (
                  <p>{address || 'No address stated'}</p>
                )
              }
            </div>
            {
              permits && (
                <ul className={styles.permits}>
                  {permits.map((permit, index) => {
                    const {
                      PERMITID,
                      PERMITNUMBER,
                      PERMITCLASS,
                      CREATEDON,
                    } = permit;

                    return (
                      <li key={index}>
                        <button
                          className={styles.permitBtn}
                          data-id={PERMITID}
                          id={`button-${PERMITID}`}
                          type="button"
                          onClick={() => {
                            dispatch({
                              type: 'set_permit-details-view-all',
                              payload: true,
                            });

                            dispatch({
                              type: 'set_permit',
                              payload: permit,
                            });
                          }}
                        >
                          <div>
                            <h5>Permit No. {PERMITNUMBER}</h5>
                            <div className={styles.metaContainer}>
                              <span>Class: </span>
                              <span>{PERMITCLASS || '-'}</span>
                            </div>
                            <div className={styles.metaContainer}>
                              <span>Created on: </span>
                              <span>{dayjs(CREATEDON).format('MMM DD, YYYY hh:mm a')}</span>
                            </div>
                          </div>
                        </button>
                      </li>
                    )
                  })}
                </ul>
              )
            }
          </div>
          <div className={styles.meta}>
            <button
              type="button"
              className={styles.metaBtn}
              onClick={() => {
                dispatch({
                  type: 'set_modal',
                  payload: true,
                });
              }}
            >
              Expedite Your Permit Process
              <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
                <circle cx="17" cy="17" r="17" fill="white" />
                <path d="M20.6912 6.76141C24.6269 7.74436 27.5597 10.9538 28.1089 14.8786C28.658 18.8034 26.7141 22.6615 23.1923 24.6364V29.1864" stroke="#F75C06" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path fillRule="evenodd" clipRule="evenodd" d="M14.0244 16.1875C14.0244 18.4312 15.8899 20.25 18.1911 20.25C20.4923 20.25 22.3577 18.4312 22.3577 16.1875C22.3577 13.9438 20.4923 12.125 18.1911 12.125C15.8899 12.125 14.0244 13.9438 14.0244 16.1875Z" stroke="#F75C06" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.6912 12.9375V5.625C15.6912 5.17627 16.0643 4.8125 16.5245 4.8125H19.8578C20.3181 4.8125 20.6912 5.17627 20.6912 5.625V12.9375" stroke="#F75C06" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.19216 21.0744L7.15216 20.8209C6.62649 20.6926 6.1976 20.3232 6.00224 19.8304C5.80687 19.3376 5.86942 18.783 6.16994 18.3433L8.19216 15.3869V14.5744C8.19216 10.7372 11.3788 7.52083 15.691 6.66608" stroke="#F75C06" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.1923 29.1875V26.7619L12.0734 26.9428C11.6136 27.0189 11.1426 26.9993 10.6912 26.8854" stroke="#F75C06" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18.1912 16.1875C18.1912 20.6748 14.4602 24.3125 9.85783 24.3125H8.19116" stroke="#F75C06" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
        </>
      )}
        <div className={styles.container}>
          {
            address && (
              <div className={styles.address}>
                <p>{address}</p>
              </div>
            )
          }
          {
            permits && (
              <div className={styles.permits}>
                <p>
                  {permits.length}
                  { permits.length > 1 ? ' permits' : ' permit'}
                </p>
                <button
                  onClick={() => {
                    dispatch({
                      type: 'set_permit-view-all',
                      payload: true,
                    });
                  }}
                >
                  <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.94592 8.35982L15.6185 8.35982C15.6831 8.36044 15.7407 8.40072 15.7635 8.46123C15.7862 8.52173 15.7695 8.59003 15.7214 8.63315L10.3343 13.376C9.98225 13.7081 9.95638 14.2595 10.2758 14.6231C10.5951 14.9867 11.145 15.0319 11.5193 14.7253L18.4932 8.5851C18.8154 8.30078 19 7.89164 19 7.46179C19 7.03193 18.8154 6.6228 18.4932 6.33847L11.5206 0.199564C11.1461 -0.103114 10.5996 -0.0564852 10.2817 0.305278C9.96382 0.667041 9.98755 1.21526 10.3355 1.54816L15.7226 6.29105C15.7708 6.33417 15.7875 6.40247 15.7647 6.46297C15.7419 6.52348 15.6843 6.56376 15.6197 6.56439L0.874495 6.56439C0.38784 6.57722 0 6.97564 0 7.46272C0 7.94981 0.38784 8.34823 0.874495 8.36106L4.94592 8.35982Z" fill="white"/>
                  </svg>
                </button>
              </div>
            )
          }
        </div>
    </div>
  )
};

export default PermitMobilePanel;
