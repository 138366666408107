import axios from "axios";

const snowflakeUrl = process.env.SNOWFLAKE_API_URL;

const mapboxUrl = process.env.MAPBOX_GEOCODE_URL;
const mapboxKey = process.env.MAPBOX_ACCESS_TOKEN;

let mapboxCancelToken;
let snowflakeCancelToken;

const snowflakeFetch = (
  endpoint,
  params = {},
  callback,
  includeCancelToken = true
) => {
  const config = { params };

  if (includeCancelToken) {
    if (typeof snowflakeCancelToken !== typeof undefined) {
      snowflakeCancelToken.cancel("Operation canceled due to new request");
    }

    snowflakeCancelToken = axios.CancelToken.source();

    config.cancelToken = snowflakeCancelToken.token;
  }

  axios
    .get(`${snowflakeUrl}${endpoint}`, config)
    .then(callback)
    .catch(() => Promise.resolve());
};

const mapboxSearch = (location) => {
  if (typeof mapboxCancelToken !== typeof undefined) {
    mapboxCancelToken.cancel("Operation canceled due to new request");
  }

  mapboxCancelToken = axios.CancelToken.source();

  // Uses a bounding box for the US. Found here:
  // https://docs.mapbox.com/mapbox-tiling-service/examples/bbox/
  return axios.get(`${mapboxUrl}${location}.json`, {
    params: {
      access_token: mapboxKey,
      bbox: "-125.0011, 24.9493, -66.9326, 49.5904", // Limits search to continental US, as best we can.
    },
    cancelToken: mapboxCancelToken.token,
    countries: "us",
  });
};

export { snowflakeFetch, mapboxSearch };
