import React from "react";

import Navbar from "../../components/navbar/navbar";
import Map from "../../components/map/map";

import styles from "./default.scss";
import SideWidget from "../../components/side-widget/side-widget";
import Modal from "../../components/modal/modal";

const Default = () => (
  <div className={styles.default}>
    <div className={styles.container}>
      <Navbar />
      <div className={styles.content}>
        <SideWidget />
        <Map />
      </div>
    </div>
    <Modal />
  </div>
);

export default Default;
